/*
 * @Description: 
 * @Author: 优
 * @Date: 2021-04-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */
export const setCookie = (name, value) => { //不设置cookie有效时间
  document.cookie = "" + name + "=" + value + ";path=/;"
}

export const setCookietime = (name, value) => {  //设置cookie的有效时间
  var days = 10;
  var exp = new Date();
  exp.setTime(exp.getTime() + days * 60 * 1000);
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/;";
}

export const delCookie = (name) => {//删除cookie
  setCookietime(name, ' ', -1);
}


export const getCookieTime = (name) => { //获取cookie（用）
  var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  var arr = document.cookie.match(reg);
  if (arr) {
    return decodeURIComponent(arr[2]);
  } else {
    return null;
  }
}