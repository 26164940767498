/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-10-12 17:24:43
 * @LastEditors: weig
 * @LastEditTime: 2021-12-21 09:34:07
 */
import {createRouter, createWebHistory} from "vue-router";
import store from '../store/index'
import {routes} from "@/config/routeConfig"
import { UaLogin } from '../utils/Login'
import { getCookieTime } from '@/common/js/Cookie.js'
import { UserSwitchPage } from '../utils/cacheProcessing'
const router = createRouter({
    history: createWebHistory(),
    routes
});
  router.beforeEach((to, form, next) => {
    document.title = "物流-" + to.meta.title;
    //路由跳转前先从缓存中取出权限相关数据，更新到store中，避免每次刷新会清空store中的权限数据
     var path = ""
     routes[0].children.find((t) => {
       if(t.path.toLowerCase()==to.path.toLowerCase() || to.path=='/404'){
        path = "matching"
       }
    })
    if (path != "matching") {
      next({ name: "404" })
    }
    if (window.location.href.indexOf("logintoken") != -1) {
      UaLogin(function (result) {
        if (!result) {
          window.location.href = "" + process.env.VUE_APP_LOGIN_URL + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
          return
        }
      });
      return
    }
    const tokenStr = getCookieTime("token");
    if (!tokenStr) {
      window.location.href = "" + process.env.VUE_APP_LOGIN_URL + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
    }else{
      if(!sessionStorage.getItem("permission")){
        UserSwitchPage(function(result){
        if(result){
         var authData = sessionStorage.getItem("permission");
        if (authData){
        var objAuthData = JSON.parse(authData);
        store.state.user.menus = objAuthData.menus;
        store.state.user.permissions = objAuthData.permissions;
        store.state.user.showCard = objAuthData.showCard;
        next();
       }}
        })
        return;
      }
       var authData = sessionStorage.getItem("permission");
        if (authData){
        var objAuthData = JSON.parse(authData);
        store.state.user.menus = objAuthData.menus;
        store.state.user.permissions = objAuthData.permissions;
        store.state.user.showCard = objAuthData.showCard;
      }
      next();
    }
   
  })
export default router;