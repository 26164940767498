<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs firstCrumbs="订单管理"
                secondCrumbs="订单列表"
                icon="Ticket" />
    <!-- 面包屑 end -->

    <!-- 内容区域 begin -->
    <div class="container">
      <div class="handle-box">
        <el-form ref="state.query"
                 :model="state.query"
                 :inline="true"
                 v-if="checkPermission([`api${state.VIEW_VERSION}:Order:OrderInfo:GetPage`])">
          <el-form-item label="用户类型">
            <el-select v-model="state.query.userType"
                       placeholder="用户类型"
                       class="handle-select mr10"
                       @change="userTypeHandle">
              <el-option v-for="item in state.userTyped"
                         :key="item.userTyper"
                         :label="item.label"
                         :value="item.userTyper"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="state.query.orderType"
                       placeholder="订单类型"
                       class="handle-select mr10"
                       @change="orderTypeHandle">
              <el-option v-for="item in state.orderTyped"
                         :key="item.orderTyper"
                         :label="item.label"
                         :value="item.orderTyper"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="state.query.orderStatus"
                       placeholder="订单状态"
                       class="handle-select mr10"
                       @change="orderStatusHandle">
              <el-option v-for="item in state.orderStatusder"
                         :key="item.orderStatuser"
                         :label="item.label"
                         :value="item.orderStatuser"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单取消状态">
            <el-select v-model="state.query.cancelStatus"
                       placeholder="订单取消状态"
                       class="handle-select mr10"
                       @change="cancelStatusHandle">
              <el-option v-for="item in state.orderCancelStatus"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单号">
            <el-input v-model="state.query.orderNo"
                      placeholder="订单号"></el-input>
          </el-form-item>
          <el-form-item label="商户单号">
            <el-input v-model="state.query.outTradeNo"
                      placeholder="商户单号"></el-input>
          </el-form-item>
          <el-form-item label="外部订单号">
            <el-input v-model="state.query.outsideOrderNo"
                      placeholder="外部订单号"></el-input>
          </el-form-item>
          <el-form-item label="下单时间">
            <el-date-picker v-model="state.query.orderDateTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="下单时间">
            <el-date-picker v-model="state.query.orderDateTime"
                            type="daterange"
                            start-placeholder="下单开始时间"
                            end-placeholder="下单结束时间"
                            class="mr10"
                            format="YYYY-MM-DD">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item style="margin-right:10px">
            <el-button type="primary"
                       icon="Search"
                       @click="handleSearch">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading">
        <el-table-column prop="num"
                         label="序号"
                         width="60"
                         align="center"></el-table-column>
        <!--  <el-table-column prop="userNickName" label="用户昵称" align="center"></el-table-column> -->
        <el-table-column prop="orderNo"
                         label="订单号"
                         align="center"
                         min-width="170px"></el-table-column>
        <el-table-column prop="outTradeNo"
                         label="商户单号"
                         align="center"
                         min-width="180px"></el-table-column>
        <el-table-column prop="outsideOrderNo"
                         label="外部订单号"
                         align="center"
                         min-width="180px"></el-table-column>
        <el-table-column prop="orderType"
                         min-width="90"
                         label="订单类型"
                         :formatter="hasBackFormat"
                         align="center"></el-table-column>
        <el-table-column prop="orderStatus"
                         min-width="90"
                         label="订单状态"
                         :formatter="hasBackFormates"
                         align="center"></el-table-column>
        <el-table-column prop="cancelStatus"
                         label="订单取消状态"
                         :formatter="hasBackFormatescancelStatus"
                         min-width="110"
                         align="center"></el-table-column>
        <!--  <el-table-column prop="goodsInfo" label="物品信息" align="center"></el-table-column>
              <el-table-column prop="amountPayable" label="付款金额" align="center"></el-table-column>
              <el-table-column prop="consigneeName" label="收货人" align="center"></el-table-column>
              <el-table-column prop="consigneePhone" label="收货手机号码" align="center" width="110px"></el-table-column> -->
        <el-table-column prop="orderDate"
                         label="下单时间"
                         align="center"
                         min-width="160px"
                         sortable></el-table-column>
        <el-table-column label="操作"
                         min-width="200"
                         align="center"
                         fixed="right"
                         v-if="checkPermission([`api${state.VIEW_VERSION}:Order:OrderInfo:Get`])">
          <template #default="scope">
            <el-button type="text"
                       icon="Check"
                       @click="handleClickDetails(scope.row)">查看详情</el-button>
            <el-button type="text"
                       icon="Close"
                       @click="handleClickCancelOrder(scope.row)"
                       v-if="scope.row.orderStatus != Enum.EnumConfig.orderStatus.已完成 && scope.row.orderStatus != Enum.EnumConfig.orderStatus.已取消 &&scope.row.orderStatus != Enum.EnumConfig.orderStatus.已送达">取消订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination :current-page="state.pageIndex"
                     :pagesizes="[10,20,50,100]"
                     :pagesize="state.pageSize"
                     :total="state.pageTotal"
                     @getPageData="getData"
                     @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 内容区域 end -->

    <!-- 详情窗口 begin -->
    <el-dialog title="订单详情"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               v-model="state.detailsVisible"
               width="70%">
      <div class="home-container">
        <el-form :model="state.form"
                 label-width="80px"
                 :inline="false">
          <el-divider content-position="left">
            <h3>基本信息</h3>
          </el-divider>
          <el-row>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="订单号"
                            prop="orderNo"
                            label-width="100px">
                <input v-model="state.form.orderNo"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="商户单号"
                            prop="outTradeNo"
                            label-width="100px">
                <input v-model="state.form.outTradeNo"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="外部订单号"
                            prop="outsideOrderNo"
                            label-width="100px">
                <input v-model="state.form.outsideOrderNo"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="下单时间"
                            prop="orderDate"
                            label-width="100px">
                <input v-model="state.form.orderDate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="接单时间"
                            prop="receivedOrderDate"
                            label-width="100px">
                <input v-model="state.form.receivedOrderDate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="预计送达时间"
                            prop="planDeliveredOrderDate"
                            label-width="100px">
                <input v-model="state.form.planDeliveredOrderDate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="送达时间"
                            prop="deliveredOrderDate"
                            label-width="100px">
                <input v-model="state.form.deliveredOrderDate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="订单类型"
                            prop="orderType"
                            label-width="100px">
                <input v-model="state.form.orderType"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="订单状态"
                            prop="orderStatus"
                            label-width="100px">
                <input v-model="state.form.orderStatus"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">
            <h3>下单信息</h3>
          </el-divider>
          <el-row>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="下单人姓名"
                            prop="userNickName"
                            label-width="100px">
                <input v-model="state.form.userNickName"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="下单手机号"
                            prop="userPhone"
                            label-width="100px">
                <input v-model="state.form.userPhone"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="收货人姓名"
                            prop="consigneeName"
                            label-width="100px">
                <input v-model="state.form.consigneeName"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="收货手机号"
                            prop="consigneePhone"
                            label-width="100px">
                <input v-model="state.form.consigneePhone"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="用户是否评价"
                            prop="isUserEvaluation"
                            label-width="100px">
                <input v-model="state.form.isUserEvaluation"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="司机是否评价"
                            prop="isDriverEvaluation"
                            label-width="100px">
                <input v-model="state.form.isDriverEvaluation"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="取消状态"
                            prop="cancelStatus"
                            label-width="100px">
                <input v-model="state.form.cancelStatus"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="取消原因"
                            prop="cancelReason"
                            label-width="100px">
                <input v-model="state.form.cancelReason"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="取消时间"
                            prop="isDriverEvaluation"
                            label-width="100px">
                <input v-model="state.form.cancelDate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="物品信息"
                            prop="goodsInfo"
                            label-width="100px">
                <input v-model="state.form.goodsInfo"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item :label="unitFormat(state.form.goodsCalcRuleType)"
                            prop="goodsLoadCount"
                            label-width="100px">
                <input v-model="state.form.goodsLoadCount"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="地址距离(km)"
                            prop="addressDistance"
                            label-width="100px">
                <input v-model="state.form.addressDistance"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="18"
                    :lg="18"
                    :xl="18">
              <el-form-item label="订单描述"
                            prop="orderDescription"
                            label-width="100px">
                <input v-model="state.form.orderDescription"
                       class="inputines"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="4"
                    :xl="4">
              <el-form-item label="">
                <el-button type="primary"
                           @click="maper">线路查看</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left"
                      class="addminer">
            <h3>地址信息</h3>
          </el-divider>
          <div v-for="(item , index) in state.form.wayCoordinates"
               :index=index
               :key=index
               class="mb1r">
            <el-row>
              <el-col :xs="1"
                      :sm="1"
                      :md="1"
                      :lg="1"
                      :xl="1">
              </el-col>
              <el-col :xs="23"
                      :sm="12"
                      :md="23"
                      :lg="23"
                      :xl="23">
                <el-card>
                  <div class="bodert"
                       @click="cononncar(index)"><span>
                      <h3>{{index+1}}-送货信息</h3>
                    </span></div>
                  <template v-if="item.caridf">
                    <el-row>
                      <el-col :xs="24"
                              :sm="24"
                              :md="8"
                              :lg="8"
                              :xl="6">
                        <el-form-item label="物品名称"
                                      prop="goodsInfo"
                                      label-width="100px">
                          <input v-model="item.goodsInfo"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="8"
                              :lg="8"
                              :xl="6">
                        <el-form-item :label="unitFormat(item.goodsCalcRuleType)"
                                      prop="goodsLoadCount"
                                      label-width="100px">
                          <input v-model="item.goodsLoadCount"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="12"
                              :lg="8"
                              :xl="6">
                        <el-form-item label="订单状态"
                                      prop="startBaseAddress"
                                      label-width="100px">
                          <input v-model="item.orderStatusDescribe"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="8"
                              :lg="8"
                              :xl="6"
                              v-if="item.isCoordinate">
                        <el-form-item label="送达坐标"
                                      prop="consigneeName"
                                      label-width="100px">
                          <input v-model="item.deliveredCoordinate"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="8"
                              :lg="8"
                              :xl="6">
                        <el-form-item label="备注"
                                      prop="consigneeName"
                                      label-width="100px">
                          <input v-model="item.consigneeName"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>

                      <el-col :xs="24"
                              :sm="24"
                              :md="12"
                              :lg="8"
                              :xl="6">
                        <el-form-item label="起始基本地址"
                                      prop="startBaseAddress"
                                      label-width="100px">
                          <input v-model="item.startBaseAddress"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="12"
                              :lg="8"
                              :xl="6">
                        <el-form-item label="终点基本地址"
                                      prop="endBaseAddress"
                                      label-width="100px">
                          <input v-model="item.endBaseAddress"
                                 class="inputin"
                                 :disabled="true" />
                        </el-form-item>

                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="12"
                              :lg="12"
                              :xl="12">
                        <el-form-item label="起始地址详情"
                                      prop="startDetailAddress"
                                      label-width="100px">
                          <input v-model="item.startDetailAddress"
                                 class="inputin deunh"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="24"
                              :lg="24"
                              :xl="24">
                        <el-form-item label="终点地址详情"
                                      prop="endDetailAddress"
                                      label-width="100px">
                          <input v-model="item.endDetailAddress"
                                 class="inputin deunh"
                                 :disabled="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24"
                              :sm="24"
                              :md="5"
                              :lg="5"
                              :xl="5"
                              v-for="(img,indexImg) in item.urlImg"
                              :key="indexImg">
                        <el-image style="width:80%; height: 150px"
                                  :src="img"
                                  :preview-src-list="[img]"
                                  :fit="fit" />
                      </el-col>
                    </el-row>
                  </template>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <el-divider content-position="left">
            <h3>司机接单信息</h3>
          </el-divider>
          <el-row>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="司机名称"
                            prop="driverNickName"
                            label-width="100px">
                <input v-model="state.form.driverNickName"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="司机手机号"
                            prop="driverPhone"
                            label-width="100px">
                <input v-model="state.form.driverPhone"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="车型名称"
                            prop="carName"
                            label-width="100px">
                <input v-model="state.form.carName"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="车牌号码"
                            prop="carLicensePlate"
                            label-width="100px">
                <input v-model="state.form.carLicensePlate"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="司机备注"
                            prop="driverRemark"
                            label-width="100px">
                <input v-model="state.form.driverRemark"
                       class="inputin deunh"
                       :disabled="true" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">
            <h3>付款信息</h3>
          </el-divider>
          <el-row>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="订单总运费(元)"
                            prop="totalFreight"
                            label-width="120px">
                <input v-model="state.form.totalFreight"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="距离运费(元)"
                            prop="distanceFreight"
                            label-width="120px">
                <input v-model="state.form.distanceFreight"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="装载运费(元)"
                            prop="loadCountFreight"
                            label-width="120px">
                <input v-model="state.form.loadCountFreight"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>

            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="基础运费(元)"
                            prop="baseFreight"
                            label-width="120px">
                <input v-model="state.form.baseFreight"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="用户小费金额(元)"
                            prop="userTipsAmount"
                            label-width="120px">
                <input v-model="state.form.userTipsAmount "
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="改价金额(元)"
                            prop="changeAmount"
                            label-width="120px">
                <input v-model="state.form.changeAmount "
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="优惠券抵扣金额(元)"
                            prop="couponDiscountAmount"
                            label-width="135px">
                <input v-model="state.form.couponDiscountAmount "
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="应付金额(元)"
                            prop="amountPayable"
                            label-width="120px">
                <input v-model="state.form.amountPayable"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="结算手续费(元)"
                            prop="settleCharge"
                            label-width="120px">
                <input v-model="state.form.settleCharge"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="红包抵扣金额(元)"
                            prop="redPackDiscountAmount"
                            label-width="120px">
                <input v-model="state.form.redPackDiscountAmount"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <el-form-item label="系统优惠金额(元)"
                            prop="systemDiscountAmount"
                            label-width="120px">
                <input v-model="state.form.systemDiscountAmount"
                       class="inputin"
                       :disabled="true" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <!-- 详情窗口 end -->

    <!-- 地图 begin-->
    <el-dialog :close-on-click-modal="false"
               v-model="dialogVisible"
               :destroy-on-close="true"
               title="路线查看"
               width="50%">
      <eup-amap :amap="amap"></eup-amap>
      <!--   开启天地图 -->
      <!--  <Eup-SkyMap :amap="amap"></Eup-SkyMap> -->
    </el-dialog>
    <!-- 地图 end-->

    <!-- 订单取消 begin -->
    <el-dialog title="取消订单"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               v-model="state.cancelOrderVisible"
               width="35%">
      <el-form ref="cancelOrderRef"
               :model="state.orderCancelform"
               label-width="100px"
               :inline="false"
               :rules="state.cancleOrderRules">
        <el-row>
          <el-col>
            <el-form-item label="订单号"
                          prop="orderNo"
                          label-width="100px">
              <el-input v-model="state.orderCancelform.orderNo"
                        :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="取消原因"
                          prop="cancelReason">
              <el-input type="textarea"
                        :rows="6"
                        placeholder="请输入内容"
                        v-model="state.orderCancelform.cancelReason">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeCancelOrderDialog">取 消</el-button>
          <el-button type="primary"
                     @click="sureCancelOrderStatus(state)">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 订单取消 end -->
  </div>
</template>
<script>
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from 'element-plus';
import EupAmap from "../../../components/EupAmap.vue";
import EupSkyMap from "../../../components/EupSkyMap.vue";
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue";
import { getPageOrderList, GetDriverPageList, getOrderDetail, updateSysCancelOrder, GetDriver } from "@/serviceApi/order/orderList";
import Enum from "@/enum/EnumConfig";
import { IsNullOrEmpty, formatDateTime } from "@/common/js/comm";
import EnumConfig from "@/enum/EnumConfig";
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: "OrderList",
  components: {
    EupPagination: EupPagination,
    EupAmap: EupAmap,
    EupCrumbs: EupCrumbs,
    EupSkyMap: EupSkyMap,
  },
  setup () {
    const dialogVisible = ref(false)
    const amap = ref()
    const cancelOrderRef = ref();
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      query: {
        orderType: "0",
        orderStatus: "0",
        orderNo: "",
        cancelStatus: "0",
        outTradeNo: "",
        outsideOrderNo: "",
        orderStartDate: "",
        orderEndDate: "",
        orderDateTime: "",
        userType: "1",
      },
      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      multipleSelection: [],
      delList: [],
      pageTotal: 0,
      form: {},
      dynamicFilter: {},
      loading: false,
      orderTyped: [
        { orderTyper: '0', label: '全部' },
        { orderTyper: '1', label: '用户发单' },
        { orderTyper: '2', label: '外部订单' },
        { orderTyper: '3', label: '外部指定司机' }
      ],
      userTyped: [
        { userTyper: '1', label: '用户订单' },
        { userTyper: '2', label: '司机订单' }
      ],
      orderStatusder: [
        { orderStatuser: '0', label: '全部' },
        { orderStatuser: '1', label: '待支付' },
        { orderStatuser: '2', label: '待接单' },
        { orderStatuser: '3', label: '已接单' },
        { orderStatuser: '11', label: '到达取货' },
        { orderStatuser: '12', label: '确认取货' },
        { orderStatuser: '21', label: '送货中' },
        { orderStatuser: '22', label: '已送达' },
        { orderStatuser: '99', label: '已完成' },
        { orderStatuser: '89', label: '已取消' },
        { orderStatuser: '88', label: '原路退回' }
      ],
      orderCancelStatus: [
        { value: '0', label: '全部' },
        { value: '1', label: '未取消' },
        { value: '2', label: '已取消' },
      ],
      detailsVisible: false,
      cancelOrderVisible: false,
      //取消订单表单数据
      orderCancelform: {
        orderNo: "",
        cancelReason: "",
        orderStatus: Enum.EnumConfig.orderStatus.已取消
      },
      cancleOrderRules: {
        cancelReason: [{ required: true, message: '取消原因不能为空！', trigger: 'blur' }]
      }
    });
    onMounted(() => {
      getData();
    })
    //获取表单信息
    const getData = () => {
      if (!IsNullOrEmpty(state.query.orderDateTime) && state.query.orderDateTime.length > 0) {
        state.query.orderStartDate = formatDateTime(state.query.orderDateTime[0], "yyyy-MM-dd HH:mm:ss");
        state.query.orderEndDate = formatDateTime(state.query.orderDateTime[1], "yyyy-MM-dd HH:mm:ss");
      }
      var params = {
        "currentPage": state.pageIndex,
        "pageSize": state.pageSize,
        "filter.orderType": state.query.orderType == '0' ? null : state.query.orderType,
        "filter.orderStatus": state.query.orderStatus == '0' ? null : state.query.orderStatus,
        "filter.orderNo": state.query.orderNo,
        "filter.cancelStatus": state.query.cancelStatus == '0' ? null : state.query.cancelStatus,
        "filter.outTradeNo": state.query.outTradeNo,
        "filter.outsideOrderNo": state.query.outsideOrderNo,
        "filter.orderStartDate": state.query.orderStartDate,
        "filter.orderEndDate": state.query.orderEndDate,
        "dynamicFilter": state.dynamicFilter
      }
      state.loading = true;
      if (state.query.userType == 2) {
        GetDriverPageList(params).then((res) => {
          if (res.code == 1) {
            state.pageTotal = res.data.total;//初始化列表数据总数
            state.tableData = res.data.list;
            //添加num序号字段
            state.tableData.forEach((data, i) => {
              data.num = i + 1;
            });
          } else {
            ElMessage.error(res.msg);
          }
          state.loading = false;
        })
      } else {
        getPageOrderList(params).then(function (res) {
          if (res.code == 1) {
            state.pageTotal = res.data.total;//初始化列表数据总数
            state.tableData = res.data.list;
            //添加num序号字段
            state.tableData.forEach((data, i) => {
              data.num = i + 1;
            });
          } else {
            ElMessage.error(res.msg);
          }
          state.loading = false;
        });
      }

    }
    // 多选操作
    const handleSelectionChange = (val) => {
      state.multipleSelection = val;
    }
    // 触发搜索按钮
    const handleSearch = () => {
      state.pageIndex = 1;
      getData();
    }

    const hasBackFormat = ((row) => {
      if (row.orderType == 1) {
        return '用户发单';
      }
      if (row.orderType == 2) {
        return '外部发单';
      }
      if (row.orderType == 3) {
        return '外部指定司机';
      }
    });
    const hasBackFormates = ((row) => {
      if (row.orderStatus == 1) {
        return '待支付';
      }
      if (row.orderStatus == 2) {
        return '待接单';
      }
      else if (row.orderStatus == 3) {
        return '已接单';
      }
      else if (row.orderStatus == 11) {
        return '到达取货';
      }
      else if (row.orderStatus == 12) {
        return '确认取货';
      }
      else if (row.orderStatus == 21) {
        return '送货中';
      }
      else if (row.orderStatus == 22) {
        return '已送达';
      }
      else if (row.orderStatus == 99) {
        return '已完成';
      }
      else if (row.orderStatus == 88) {
        return '原路退回';
      }
      else if (row.orderStatus == 89) {
        return '已取消';
      } else {
        return '未知状态';
      }
    });
    const hasBackFormatescancelStatus = (row) => {
      if (row.cancelStatus == "1") {
        return '未取消'
      }
      else {
        return '已取消'
      }
    };
    //详情
    //用户类型
    const userTypeHandle = () => {
      getData();
    }
    //切换订单类型
    const orderTypeHandle = () => {
      getData();
    }
    //切换订单状态
    const orderStatusHandle = () => {
      getData();
    }
    //切换订单取消状态
    const cancelStatusHandle = () => {
      getData();
    }
    const handleClickDetails = ((row) => {
      state.detailsVisible = true;
      if (state.query.userType == 2) {
        let paramses = {
          orderNo: row.orderNo,
          driverOrderNo: row.driverOrderNo
        }
        GetDriver(paramses).then((res) => {
          if (res.code == 1) {
            state.form = res.data;
            for (let index = 0; index < state.form.wayCoordinates.length; index++) {
              state.form.wayCoordinates[index].caridf = true;
              if (state.form.wayCoordinates[index].deliveredCoordinate) {
                state.form.wayCoordinates[index].isCoordinate = true;
              }
              if (state.form.wayCoordinates[index].deliveredImg) {
                state.form.wayCoordinates[index].urlImg = state.form.wayCoordinates[index].deliveredImg.split("|");
                for (let j = 0; j < state.form.wayCoordinates[index].urlImg.length; j++) {
                  state.form.wayCoordinates[index].urlImg[j] = state.form.wayCoordinates[index].urlImg[j].replace(process.env.VUE_APP_API_BASE, "");
                }
              }
            }

            //用户评价
            if (state.form.isUserEvaluation == false) {
              state.form.isUserEvaluation = '未评价'
            }
            else {
              state.form.isUserEvaluation = '已评价'
            }
            //司机评价
            if (state.form.isDriverEvaluation == false) {
              state.form.isDriverEvaluation = '未评价'
            }
            else {
              state.form.isDriverEvaluation = '已评价'
            }
            //物品计价类型
            if (state.form.goodsCalcRuleType == 1) {
              state.form.goodsCalcRuleType = '送货距离'
            }
            else if (state.form.goodsCalcRuleType == 2) {
              state.form.goodsCalcRuleType = '物品重量(kg)'
            }
            else if (state.form.goodsCalcRuleType == 3) {
              state.form.goodsCalcRuleType = '物品体积(m³)'
            }
            else {
              state.form.goodsCalcRuleType = '物品面积(㎡)'
            }
            //订单类型
            if (state.form.orderType == 1) {
              state.form.orderType = '用户发单'
            }
            else if (row.orderType == 2) {
              state.form.orderType = '外部发单'
            } else {
              state.form.orderType = '外部指定司机发单'
            }
            //订单状态
            if (state.form.orderStatus == 1) {
              state.form.orderStatus = '待支付'
            }
            else if (state.form.orderStatus == 2) {
              state.form.orderStatus = '待接单'
            }
            else if (state.form.orderStatus == 3) {
              state.form.orderStatus = '已接单'
            }
            else if (state.form.orderStatus == 11) {
              state.form.orderStatus = '到达取货'
            }
            else if (state.form.orderStatus == 12) {
              state.form.orderStatus = '确认取货'
            }
            else if (state.form.orderStatus == 21) {
              state.form.orderStatus = '送货中'
            }
            else if (state.form.orderStatus == 22) {
              state.form.orderStatus = '已送达'
            }
            else if (state.form.orderStatus == 88) {
              state.form.orderStatus = '原路退回'
            }
            else if (state.form.orderStatus == 89) {
              state.form.orderStatus = '已取消'
            }
            else if (state.form.orderStatus == 99) {
              state.form.orderStatus = '已完成'
            }
            else {
              state.form.orderStatus = '未知状态'
            }
            //订单取消状态
            if (1 == state.form.cancelStatus) {
              state.form.cancelStatus = "未取消";
            } else {
              state.form.cancelStatus = "已取消";
            }
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        var params = {
          "orderNo": row.orderNo
        };
        getOrderDetail(params).then(function (res) {
          if (res.code == 1) {
            state.form = res.data;
            for (let index = 0; index < state.form.wayCoordinates.length; index++) {
              state.form.wayCoordinates[index].caridf = true;
              if (state.form.wayCoordinates[index].deliveredCoordinate) {
                state.form.wayCoordinates[index].isCoordinate = true;
              }
              if (state.form.wayCoordinates[index].deliveredImg) {
                state.form.wayCoordinates[index].urlImg = state.form.wayCoordinates[index].deliveredImg.split("|");
                for (let j = 0; j < state.form.wayCoordinates[index].urlImg.length; j++) {
                  state.form.wayCoordinates[index].urlImg[j] = state.form.wayCoordinates[index].urlImg[j].replace(process.env.VUE_APP_API_BASE, "");
                }
              }
            }
            //用户评价
            if (state.form.isUserEvaluation == false) {
              state.form.isUserEvaluation = '未评价'
            }
            else {
              state.form.isUserEvaluation = '已评价'
            }
            //司机评价
            if (state.form.isDriverEvaluation == false) {
              state.form.isDriverEvaluation = '未评价'
            }
            else {
              state.form.isDriverEvaluation = '已评价'
            }
            //物品计价类型
            if (state.form.goodsCalcRuleType == 1) {
              state.form.goodsCalcRuleType = '送货距离'
            }
            else if (state.form.goodsCalcRuleType == 2) {
              state.form.goodsCalcRuleType = '物品重量(kg)'
            }
            else if (state.form.goodsCalcRuleType == 3) {
              state.form.goodsCalcRuleType = '物品体积(m³)'
            }
            else {
              state.form.goodsCalcRuleType = '物品面积(㎡)'
            }
            //订单类型
            if (state.form.orderType == 1) {
              state.form.orderType = '用户发单'
            }
            else if (row.orderType == 2) {
              state.form.orderType = '外部发单'
            } else {
              state.form.orderType = '外部指定司机发单'
            }
            //订单状态
            if (state.form.orderStatus == 1) {
              state.form.orderStatus = '待支付'
            }
            else if (state.form.orderStatus == 2) {
              state.form.orderStatus = '待接单'
            }
            else if (state.form.orderStatus == 3) {
              state.form.orderStatus = '已接单'
            }
            else if (state.form.orderStatus == 11) {
              state.form.orderStatus = '到达取货'
            }
            else if (state.form.orderStatus == 12) {
              state.form.orderStatus = '确认取货'
            }
            else if (state.form.orderStatus == 21) {
              state.form.orderStatus = '送货中'
            }
            else if (state.form.orderStatus == 22) {
              state.form.orderStatus = '已送达'
            }
            else if (state.form.orderStatus == 88) {
              state.form.orderStatus = '原路退回'
            }
            else if (state.form.orderStatus == 89) {
              state.form.orderStatus = '已取消'
            }
            else if (state.form.orderStatus == 99) {
              state.form.orderStatus = '已完成'
            }
            else {
              state.form.orderStatus = '未知状态'
            }
            //订单取消状态
            if (1 == state.form.cancelStatus) {
              state.form.cancelStatus = "未取消";
            } else {
              state.form.cancelStatus = "已取消";
            }
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    });

    /**
     * @description 子组件返回分页数据
     * @author weig
     * @param {Object} obj
     */
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    }
    const unitFormat = (val) => {
      if (val == 1) {
        return '送货距离'
      }
      else if (val == 2) {
        return '物品重量(kg)'
      }
      else if (val == 3) {
        return '物品体积(m³)'
      }
      else {
        return '物品面积(㎡)'
      }
    };
    const cononncar = (index) => {
      if (state.form.wayCoordinates[index].caridf == true) {
        state.form.wayCoordinates[index].caridf = false
      } else {
        state.form.wayCoordinates[index].caridf = true
      }
    }
    const maper = () => {
      dialogVisible.value = true;
      amap.value = state.form
    };

    /**
     * @description 打开取消订单对话框
     * @author weig
     * @param {object} row 当前行对象
     */
    function handleClickCancelOrder (row) {
      state.cancelOrderVisible = true;
      state.orderCancelform.orderNo = row.orderNo;
      state.orderCancelform.cancelReason = row.cancelReason;
    }
    /**
     * @description 关闭取消订单对话框
     * @author weig
     */
    function closeCancelOrderDialog () {
      state.cancelOrderVisible = false;
      cancelOrderRef.value.resetFields();//清空表单数据
      // state.orderCancelform.orderNo="";
      // state.orderCancelform.cancelReason="";
    }
    /**
     * @description 取消订单
     * @author weig
     */
    function sureCancelOrderStatus () {
      if (IsNullOrEmpty(state.orderCancelform.cancelReason)) {
        ElMessage.warning("取消原因不能为空!");
        return;
      }
      let params = {
        orderNo: state.orderCancelform.orderNo,
        cancelReason: state.orderCancelform.cancelReason,
        orderStatus: state.orderCancelform.orderStatus
      }
      updateSysCancelOrder(params).then(res => {
        if (1 == res.code) {
          ElMessage.success("操作成功！");
          cancelOrderRef.value.resetFields();//清空表单数据
          state.cancelOrderVisible = false;
          getData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    }
    return {
      state,
      cononncar, maper,
      handleSelectionChange,
      handleSearch,
      unitFormat,
      hasBackFormat,
      hasBackFormates,
      hasBackFormatescancelStatus,
      handleClickDetails,
      orderTypeHandle,
      userTypeHandle,
      orderStatusHandle,
      cancelStatusHandle,
      getData,
      resPageData,
      dialogVisible,
      amap,
      handleClickCancelOrder,
      Enum,
      closeCancelOrderDialog,
      cancelOrderRef,
      sureCancelOrderStatus
    }
  }
};
</script>
<style scoped>
</style>
