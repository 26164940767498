/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:12:32
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取司机列表信息
export const postdDriverApplyInfo = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Driver/DriverApplyInfo/GetPage`, params);
}
/* 司机申请详情 */
export const getDriverApplyInfoByApplyID = (params) => { 
    return request.apiGet(`/api${API_VERSION}/Driver/DriverApplyInfo/Get?applyId=${params}`)
}
//司机审核
export const updateApplyApproval = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Driver/DriverApplyInfo/UpdateApplyApproval`, params)
}
