/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-10-18 14:33:12
 * @LastEditors: weig
 * @LastEditTime: 2021-12-29 10:20:22
 */
import Home from "../views/Home.vue";
import SysHome from "../views/menuNav/SysHome.vue";
import Userlist from "../views/menuNav/User/UserList.vue";
import UserConfig from "../views/menuNav/User/UserConfig.vue";
import UserAddress from "../views/menuNav/User/Address.vue";
import LoginLog from "../views/menuNav/User/LoginLog.vue";
import OperLog from "../views/menuNav/User/OperLog.vue";
import OrderList from "../views/menuNav/Order/List.vue";
import DriverList from "../views/menuNav/Driver/List.vue";
import ApplyList from "../views/menuNav/Driver/Apply.vue";
import PriceConfig from "../views/menuNav/Freight/PriceConfig.vue";
import VehicleConfig from "../views/menuNav/Freight/VehicleConfig.vue";
import AreaManage from "../views/menuNav/System/AreaManage.vue";
import ApplyCancel from "../views/menuNav/Driver/ApplyCancel.vue";
export const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: `/SysHome`, 
        caseSensitive: false, 
        children: [
            {
                path: `/Syshome`,
                name: "SysHome",
                meta: {
                    title: '系统首页',
                    permission: true
                },
                component: SysHome,
                caseSensitive: false
            },
            {
                path: `/User/UserList`,
                name: "UserList",
                meta: {
                    title: '用户列表',
                    permission: true
                },
                component: Userlist,
                caseSensitive: false
            },
            {
                path: `/User/Address`,
                name: "Address",
                meta: {
                    title: '用户地址',
                    permission: true
                },
                component: UserAddress,
                caseSensitive: false
            },
            {
                path: `/User/LoginLog`,
                name: "LoginLog",
                meta: {
                    title: '登陆日志',
                    permission: true
                },
                component: LoginLog,
                caseSensitive: false
            },
            {
                path: `/User/OperLog`,
                name: "OperLog",
                meta: {
                    title: '操作日志',
                    permission: true
                },
                component: OperLog,
                caseSensitive: false
            },
            {
                path: `/User/UserConfig`,
                name: "UserConfig",
                meta: {
                    title: '用户配置',
                    permission: true
                },
                component: UserConfig,
                caseSensitive: false
            },
            {
                path: `/Order/OrderList`,
                name: "OrderList",
                meta: {
                    title: '订单列表',
                    permission: true
                },
                component: OrderList,
                caseSensitive: false
            },
            {
                path: `/Driver/DriverList`,
                name: "DriverList",
                meta: {
                    title: '司机列表',
                    permission: true
                },
                component: DriverList,
                caseSensitive: false
            },
            {
                path: `/Driver/Apply`,
                name: "ApplyList",
                meta: {
                    title: '司机申请',
                    permission: true
                },
                component: ApplyList,
                caseSensitive: false
            },
            {
                path: `/Driver/ApplyCancel`,
                name: "ApplyCancel",
                meta: {
                    title: '司机解绑',
                    permission: true
                },
                component: ApplyCancel,
                caseSensitive: false
            },
            {
                path: `/Freight/PriceConfig`,
                name: "PriceConfig",
                meta: {
                    title: '价格配置',
                    permission: true
                },
                component: PriceConfig,
                caseSensitive: false
            },
            {
                path: `/Freight/VehicleConfig`,
                name: "VehicleConfig",
                meta: {
                    title: '车型配置',
                    permission: true
                },
                component: VehicleConfig,
                caseSensitive: false
            },
            {
                path: `/System/AreaManage`,
                name: "AreaManage",
                meta: {
                    title: '地区管理',
                    permission: true
                },
                component: AreaManage,
                caseSensitive: false
            },
            {
                path: `/System/Tenant`,
                name: "Tenant",
                meta: {
                    title: '租户管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/System/Tenant.vue"),
                caseSensitive: false
            },
            {
                path: `/System/Cache`,
                name: "Cache",
                meta: {
                    title: '缓存管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/System/Cache.vue"),
                caseSensitive: false
            },
            {
                path: `/System/Feedback`,
                name: "Feedback",
                meta: {
                    title: '建议反馈管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/System/Feedback.vue"),
                caseSensitive: false
            },
            {
                path: `/Pay/PayRecord`,
                name: "PayRecord",
                meta: {
                    title: '支付记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Pay/PayRecord.vue"),
                caseSensitive: false
            },
            {
                path: `/Pay/RefundRecord`,
                name: "RefundRecord",
                meta: {
                    title: '退款记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Pay/RefundRecord.vue"),
                caseSensitive: false
            },
            {
                path: `/Pay/TransferRecord`,
                name: "TransferRecord",
                meta: {
                    title: '转账记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Pay/TransferRecord.vue"),
                caseSensitive: false
            },
            {
                path:`/Fund/SysBalance`,
                name:"SysBalance",
                 component: () => import ("../views/menuNav/Fund/SysBalance.vue"),
                 meta:{
                     title:'系统余额',
                     permission: true
                 },
                 caseSensitive: false
            },
            {
                path:`/Fund/Frozen`,
                name:"Frozen",
                 component: () => import ("../views/menuNav/Fund/Frozen.vue"),
                 meta:{
                     title:'系统冻结',
                     permission: true
                 },
                 caseSensitive: false
            },
            {
                path: `/Fund/BalanceRecord`,
                name: "Balance",
                meta: {
                    title: '余额记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Fund/Balance.vue"),
                caseSensitive: false
            },
            {
                path: `/Fund/RechargeRecord`,
                name: "RechargeRecord",
                meta: {
                    title: '充值记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Fund/Recharge.vue"),
                caseSensitive: false
            },
            {
                path: `/Fund/WithdrawRecord`,
                name: "WithdrawRecord",
                meta: {
                    title: '提现记录',
                    permission: true
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/menuNav/Fund/Withdraw.vue"),
                caseSensitive: false
            },
            {
                path: `/Permission/Admin/Role`,
                name: "Role",
                meta: {
                    title: '角色管理',
                    permission: true
                },
                component: () => import (
                "../views/menuNav/Permission/Role.vue"),
                caseSensitive: false
            }, 
            {
                path: `/Permission/Admin/UserRole`,
                name: "UserRole",
                meta: {
                    title: '用户角色',
                    permission: true
                },
                component: () => import ("../views/menuNav/Permission/UserRole.vue"),
                caseSensitive: false
            },
            {
                path: `/Permission/Admin/Interface`,
                name: "Interface",
                meta: {
                    title: '接口管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/Permission/Api.vue"),
                caseSensitive: false
            },
            {
                path: `/Permission/Admin/ViewManage`,
                name: "ViewManage",
                meta: {
                    title: '视图管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/Permission/ViewManage.vue"),
                caseSensitive: false
            },
            {
                path: `/Permission/Admin/PermissionManage`,
                name: "PermissionManage",
                meta: {
                    title: '权限管理',
                    permission: true
                },
                component: () => import ("../views/menuNav/Permission/PermissionManage.vue"),
                caseSensitive: false
            },
            // {
            //     path: `/Permission/Admin/Role-Permission`,
            //     name: "RolePermission",
            //     meta: {
            //         title: '角色权限',
            //         permission: true
            //     },
            //     component: () => import ("../views/menuNav/Permission/Role-Permission.vue")
            // },
            {
                path:`/Image/Image`,
                name:"Image",
                component: () => import ("../views/menuNav/Image/Image.vue"),
                meta:{
                    title:'图片中心',
                    permission: true
                },
                caseSensitive: false
             },
             {
                path:`/Sales/RedPack`,
                name:"RedPack",
                  component: () => import ("../views/menuNav/Sales/RedPack.vue"),
                  meta:{
                      title:'红包配置',
                      permission: true
                  },
                  caseSensitive: false
            },
            {
              path:`/Sales/Coupon`,
              name:"Coupon",
               component: () => import ("../views/menuNav/Sales/Coupon.vue"),
               meta:{
                   title:'优惠券配置',
                   permission: true
               },
               caseSensitive: false
            },
            {
                path:`/Sales/SalesBanner`,
                name:"SalesBanner",
                component: () => import ("../views/menuNav/Sales/SalesBanner.vue"),
                meta:{
                    title:'横幅配置',
                    permission: true
                },
                caseSensitive: false
             },
             {
                path: `/Article/ArticleType`,
                name: "ArticleType",
                component: () => import ("../views/menuNav/Article/ArticleType.vue"),
                meta: {
                    title: '内容分类',
                    permission: true
                },
                caseSensitive: false
            },    
            {
                path: `/Article/ArticleInfo`,
                name: "ArticleInfo",
                component: () => import ("../views/menuNav/Article/ArticleInfo.vue"),
                meta: {
                    title: '内容详情',
                    permission: true
                },
                caseSensitive: false
            },  
            {
                path: `/Chat/ChatSysMsg`,
                name: "ChatSysMsg",
                component: () => import ("../views/menuNav/Chat/ChatSysMsg.vue"),
                meta: {
                    title: '消息管理',
                    permission: true
                },
                caseSensitive: false
            },              
        ]
    },
    {
        path: "/Login",
        name: "Login",
        meta: {
            title: '登录',
            permission: true
        },
        
        component: () => import (/* webpackChunkName: "login" */"../views/Account/Login.vue"),
        caseSensitive: false
    },
    {
        path: `/Demo`,
        name: "Demo",
        meta: {
            title: '测试Demo',
            permission: true
        },
        component: () => import ("../views/menuNav/Demo.vue"),
        caseSensitive: false
    },
    {
        path: "/i18n",
        name: "i18n",
        meta: {
            title: '国际化语言',
            permission: true
        },
        component: () => import (
        /* webpackChunkName: "i18n" */
        "../views/I18n.vue"),
        caseSensitive: false
    }, {
        path: "/upload",
        name: "upload",
        meta: {
            title: '上传插件',
            permission: true
        },
        component: () => import (
        /* webpackChunkName: "upload" */
        "../views/Upload.vue"),
        caseSensitive: false
    }, {
        path: "/icon",
        name: "icon",
        meta: {
            title: '自定义图标',
            permission: true
        },
        component: () => import (
        /* webpackChunkName: "icon" */
        "../views/Icon.vue"),
        caseSensitive: false
    }, {
        path: '/404',
        name: '404',
        meta: {
            title: '找不到页面',
            permission: true
        },
        component: () => import (/* webpackChunkName: "404" */
        '../views/404.vue'),
        caseSensitive: false
    }, {
        path: '/403',
        name: '403',
        meta: {
            title: '没有权限',
            permission: true
        },
        component: () => import (/* webpackChunkName: "403" */
        '../views/403.vue'),
        caseSensitive: false
    }
];