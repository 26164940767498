<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-05-24 10:24:20
 * @LastEditors: weig
 * @LastEditTime: 2022-02-10 14:50:25
-->
<template>
    <div>
        <div class="header">
            <!-- 折叠按钮 -->
            <div class="collapse-btn" @click="collapseChage">
                <i v-if="!collapse" class="el-icon-s-fold"></i>
                <i v-else class="el-icon-s-unfold"></i>
                <el-icon v-if="!collapse"><DArrowLeft/></el-icon>
                <el-icon v-else><DArrowRight/></el-icon>
            </div>
            <div class="logo">后台管理系统</div>
            <div class="header-right">
                <div class="header-user-con">
                <div class="user-avator" v-if="superAdministrator">
                 <el-select v-model="value" class="m-2" filterable  placeholder="租户选择" size="large" @change="selChange">
                     <el-option
                     v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"/>
                 </el-select>
                </div>
                    <div class="user-avator">
                        <img :src="(userInfo.portraitUrl ? userInfo.portraitUrl: defaultPortraitUrl)" style="object-fit: cover"/>
                    </div>
                    <!-- 用户名下拉菜单 -->
                    <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{userInfo.nickName}}
                            <!-- <i class="el-icon-caret-bottom"></i> -->
                            <el-icon style="margin-left: 6px;"><CaretBottom/></el-icon>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item divided command="updatepassword">修改密码</el-dropdown-item>
                                <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <el-dialog title="修改密码" v-model="editPassWordVisible" width="30%" @close="closeUpdatePWDialog()">
            <el-form ref="PWEidtInfo" :model="updatePassWord" label-width="80px" :rules="rulesForm">
                <el-form-item label="账号" prop="userName">
                    <el-input placeholder="账号" v-model="updatePassWord.userName" disabled></el-input>
                </el-form-item>
                <el-form-item label="旧密码" prop="oldPassword">
                    <el-input placeholder="请输入旧密码" v-model="updatePassWord.oldPassword" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input placeholder="请输入新密码" v-model="updatePassWord.newPassword" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input placeholder="请输入密码" v-model="updatePassWord.confirmPassword" show-password></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeUpdatePWDialog">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { logout,UpdatePassword} from "@/serviceApi/auth";
import { ElMessage } from 'element-plus'
import { passwordMd5 } from "@/utils/password.js";
import EnumConfig from "@/enum/EnumConfig";
import { GetPageWhole } from '@/serviceApi/system/tenant'
import { getCookieTime,setCookie,delCookie } from '@/common/js/Cookie.js';
import { RefreshToken } from '@/serviceApi/auth';
import defaultPortraitUrl from "../assets/img/img.jpg";
import{GetTokeUserInfo} from '@/common/js/utils';
export default {
    data() {
        var that = this;
        //校验新旧密码是否一致
        var validateNewPassword = (rule, value, callback)=>{
            if (that.updatePassWord.oldPassword === value){
                return callback(new Error("新密码与旧密码一致！"));
            }
        };
        //校验新密码与确认密码是否一致
        var validateConfirmPassword = (rule, value, callback)=>{
            if (that.updatePassWord.newPassword != value){
                return callback(new Error("俩次输入密码不一致！"));
            }
        }
        return {
            options: [{ value:'all',label: '全部租户',}],
            value:"全部租户",
            defaultPortraitUrl: defaultPortraitUrl,
            fullscreen: false,
            superAdministrator:false,
            // name: "linxin",
            message: 2,
            userInfo:{
                email: "",
                nickName: "",
                openId: "",
                phone: "",
                portrait: "",
                portraitUrl:"",
                userName:"",
                userStatus: 0
            },
            updatePassWord:{
                userName: "",
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",

            },
            editPassWordVisible: false,
            rulesForm:{
                oldPassword: [
                    {required: true,message: "请输入旧密码！",trigger: "blur"},
                    {min: 6, max: 16, message: '密码长度6-16字符', trigger: 'blur'}
                ],
                newPassword:[
                    {required: true,message: "请输入新密码！",trigger: "blur"},
                    {min: 6, max: 16, message: '密码长度6-16字符！', trigger: 'blur'},
                    { validator: validateNewPassword, trigger: 'blur' }
                ],
                confirmPassword:[
                    {required: true,message: "请再次输入密码！",trigger: "blur"},
                    {min: 6, max: 16, message: '密码长度6-16字符！', trigger: 'blur'},
                    { validator: validateConfirmPassword, trigger: 'blur' }
                ],
            },
        };
    },
    computed: {
        collapse() {
            return this.$store.getters.collapse;
        }
    },
    methods: {
        selChange(){
         let  oldToken = getCookieTime("token");
         let tenantId=this.value;
        let params={
         token:oldToken,
         tenantId:tenantId
        }
        RefreshToken(params).then((res)=>{
              if(res.code==1){
                setCookie("token", res.data.token);
                sessionStorage.setItem("TenantId",this.value);
                 window.location.href=window.location.href;
              }
        });
        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == "loginout") {//退出
                var url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
                logout(url).then((res) => {
                    setCookie("token", "");
                    delCookie("ASP.NET_SessionId");
                    delCookie("SessionId");
                    sessionStorage.clear();
                    sessionStorage.setItem("tagsList", "");
                    window.location.href = res.data;
                });
            } else if (command == "updatepassword"){//修改密码
                this.editPassWordVisible = true;
            }
        },
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("tags/hadndleCollapse", !this.collapse);
        },
        //获取当前用户信息
        getCurrUserInfo_func(){
            let token = getCookieTime("token");
            //此方法会跑俩次，因为该页面会加载俩次，第一次未通过路由守卫，第二次通过
            //第一次进入此方法时，token为空，（此时未经过路由守卫，token可能为空或者过期等）报错，报错后再登录，客户体验不好。（处理办法，不提示错误，等通过路由守卫后重新获取token）
            //通过路由守卫那里重新获取token再次登录，再跑第二次这里，此时的token已经重新获取了
            if (token){
                    this.userInfo.email=this.$store.getters.userInfo.email;
                    this.userInfo.nickName=this.$store.getters.userInfo.nickName;
                    this.userInfo.openId=this.$store.getters.userInfo.openId;
                    this.userInfo.phone=this.$store.getters.userInfo.phone;
                    this.userInfo.portrait=this.$store.getters.userInfo.portrait;
                    this.userInfo.portraitUrl=this.$store.getters.userInfo.portraitUrl;
                    this.userInfo.userName=this.$store.getters.userInfo.userName;
                    this.userInfo.userStatus=this.$store.getters.userInfo.userStatus;
                    this.updatePassWord.userName = this.$store.getters.userInfo.userName;
                    //异步操作
                    // this.$store.dispatch("user/getPermissionPointMenuInfo");
            }
        },
        //关闭修改密码对话框
        closeUpdatePWDialog(){
            this.editPassWordVisible = false;
            this.$refs["PWEidtInfo"].resetFields();//重新初始化表单
        },
        //保存修改密码
        saveEdit(){
            if (this.updatePassWord.oldPassword == "" || this.updatePassWord.oldPassword == undefined || this.updatePassWord.oldPassword == null){
                ElMessage.error("请输入旧密码！");
                return;
            }
            if (this.updatePassWord.newPassword == "" || this.updatePassWord.newPassword == undefined || this.updatePassWord.newPassword == null){
                ElMessage.error("请输入新密码！");
                return;
            }
            if (this.updatePassWord.confirmPassword == "" || this.updatePassWord.confirmPassword == undefined || this.updatePassWord.confirmPassword == null){
                ElMessage.error("请再次输入密码！");
                return;
            }
            if (this.updatePassWord.oldPassword == this.updatePassWord.newPassword){
                ElMessage.error("新密码与旧密码一致！");
                return;
            }
            if (this.updatePassWord.confirmPassword != this.updatePassWord.newPassword){
                ElMessage.error("俩次输入密码不一致！");
                return;
            }
            let params = {
                "oldPassword": passwordMd5(this.updatePassWord.oldPassword),
                "newPassword":passwordMd5( this.updatePassWord.newPassword),
                "version": 0
            };          
            UpdatePassword(params).then((res)=>{
                if (res.msg == '修改成功' && res.msgCode == 'OK'){
                    ElMessage.success("密码修改成功！");  
                    this.editPassWordVisible = false;       
                }
            });
        }
    },
    mounted() {
       let tenant= sessionStorage.getItem("TenantId");
       this.value=tenant;
       if(tenant==null){
        if(GetTokeUserInfo().ti==""){
          this.value="all";
        }else{
            this.value=GetTokeUserInfo().ti;
        }
       }
        if (document.body.clientWidth < 1300) {
            this.collapseChage();
        }
        this.getCurrUserInfo_func();
        var userInfoP = sessionStorage.getItem("userInfo");
        var objUserInfo = JSON.parse(userInfoP);
      //超级管理员
      if (objUserInfo.userName === EnumConfig.EnumConfig.Account.SuperAdmin){
        var _this=this;
        GetPageWhole().then(function(res){
            if(res.code==1){
              for (let index = 0; index < res.data.length; index++) {
              let resArr={};  
               resArr.label = res.data[index].name;
               resArr.value = res.data[index].tenantId; 
               _this.options.push(resArr);
              }
            _this.superAdministrator= true;
            }
        })
      }
      
    }
};
</script>
<style scoped>
</style>
