import { GetCurrUserInfo} from "@/serviceApi/auth";
import store from '../store/index';
import { ElMessage } from 'element-plus'
import{GetTokeUserInfo} from '../common/js/utils';
import { getPermissionsPointMenu} from '@/serviceApi/permission/permission'
export async function UserInformation(callback){
   if(!sessionStorage.getItem("userInfo")){
    GetCurrUserInfo().then(res =>{
        if(res.code==1){ 
         store.state.user.userInfo = res.data;//全局缓存当前用户信息
         res.data.token=GetTokeUserInfo().uid;
         sessionStorage.setItem("userInfo", JSON.stringify(res.data));
         store.commit("user/getPermissionPointMenuInfo_sync");
         callback(true)
        }else{
         return ElMessage.error(res.msg);
        }   
       });
   }
   let token=JSON.parse(sessionStorage.getItem("userInfo")).token;
   if(JSON.parse(sessionStorage.getItem("userInfo")) && token!=GetTokeUserInfo().uid){
     GetCurrUserInfo().then(res =>{
         if(res.code==1){
          store.state.user.userInfo = res.data;//全局缓存当前用户信息
          res.data.token=GetTokeUserInfo().uid;
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          store.commit("user/getPermissionPointMenuInfo_sync");
          callback(true)
         }else{
          return ElMessage.error(res.msg);
         }   
        });
   }
}
export async function UserSwitchPage(callback){
    if(!JSON.parse(sessionStorage.getItem("userInfo"))|| !JSON.parse(sessionStorage.getItem("permission"))){
        GetCurrUserInfo().then(res =>{
            if(res.code==1){ 
             store.state.user.userInfo = res.data;//全局缓存当前用户信息
             res.data.token=GetTokeUserInfo().uid;
             sessionStorage.setItem("userInfo", JSON.stringify(res.data));
             getPermissionsPointMenu().then((res) =>{
                if(res.code == 1){
                  let permissions = res.data.permissions;
                  let menus = res.data.menus;
                  let showCard = true;
                  var data = {
                    menus: menus,
                    permissions: permissions,
                    showCard:showCard,
                    token:GetTokeUserInfo().uid
                  };
                  sessionStorage.setItem("permission", JSON.stringify(data));
                  callback(true)
                }
                else{
                    return ElMessage.error(res.msg);
                }
              });
             
            }else{
             return ElMessage.error(res.msg);
            }   
           });
    }
    if(JSON.parse(sessionStorage.getItem("userInfo")) || JSON.parse(sessionStorage.getItem("permission"))){
        if(JSON.parse(sessionStorage.getItem("userInfo")).token!=GetTokeUserInfo().uid|| JSON.parse(sessionStorage.getItem("permission")).token!=GetTokeUserInfo().uid){
            GetCurrUserInfo().then(res =>{
                if(res.code==1){ 
                 store.state.user.userInfo = res.data;//全局缓存当前用户信息
                 res.data.token=GetTokeUserInfo().uid;
                 sessionStorage.setItem("userInfo", JSON.stringify(res.data));
                 getPermissionsPointMenu().then((res) =>{
                    if(res.code == 1){
                      let permissions = res.data.permissions;
                      let menus = res.data.menus;
                      let showCard = true;
                      var data = {
                        menus: menus,
                        permissions: permissions,
                        showCard:showCard,
                        token:GetTokeUserInfo().uid
                      };
                      sessionStorage.setItem("permission", JSON.stringify(data));
                      callback(true)
                    }
                    else{
                        return ElMessage.error(res.msg);
                    }
                  });
                 
                }else{
                 return ElMessage.error(res.msg);
                }   
               });
        }
    }
 }