<template>
  <div>
    <div id="mapDiv"></div>
  </div>
</template>
<script>
import { onMounted } from 'vue';
import stanet from "@/assets/img/map/start.png";
import endnet from "@/assets/img/map/end.png";
import tuji from "@/assets/img/map/midway.png";
import { gcj02towgs84 } from "@/common/js/jquery.map ";
export default {
  props: [
    'amap'
  ],
  setup (props, context) {
    onMounted(() => {
      let lngLat = props.amap.startCoordinate.split(',');
      let address = {
        lng: lngLat[0],
        lat: lngLat[1],
      };
      onLoad(address, props.amap.wayCoordinates);
    });
    let map;
    let drivingRoute;
    const onLoad = (address, routeInformation) => {
      address = gcj02towgs84(parseFloat(address.lng), parseFloat(address.lat));
      map = new T.Map("mapDiv");
      map.centerAndZoom(new T.LngLat(address[0], address[1]), 13);
      var config = {
        policy: 0, //驾车策略
        onSearchComplete: searchResult, //检索完成后的回调函数
      };
      drivingRoute = new T.DrivingRoute(map, config);
      map.clearOverLays();
      let startLngLat = new T.LngLat(address[0], address[1]);
      let routeIn = [];
      if (routeInformation[routeInformation.length - 1].endCoordinate != 0) {
        routeInformation[routeInformation.length - 1].endCoordinate.split(',');
      } else {
        routeIn = address;
      }
      routeIn = gcj02towgs84(parseFloat(routeIn[0]), parseFloat(routeIn[1]));
      let endLngLat = new T.LngLat(routeIn[0], routeIn[1]);
      let startingData = [];
      if ((routeInformation.length - 1) > 0) {
        for (let index = 0; index < routeInformation.length - 1; index++) {
          if (routeInformation[index].endCoordinate) {
            let latLng = routeInformation[index].endCoordinate.split(',');
            let mid = gcj02towgs84(parseFloat(latLng[0]), parseFloat(latLng[1]));
            startingData.push(new T.LngLat(mid[0], mid[1]));
          } else {
            startingData.push(new T.LngLat(address[0], address[1]));
          }
        }
      } else {
        startingData = null;
      }
      //驾车路线搜索
      drivingRoute.search(startLngLat, endLngLat, startingData);
    }

    const createRoute = (lnglats, lineColor) => {
      //创建线对象
      let line = new T.Polyline(lnglats, {
        color: "#2C64A7",
        weight: 5,
        opacity: 0.9,
      });
      //向地图上添加线
      map.addOverLay(line);
    }
    //添加起始点
    const createStartMarker = (result) => {
      var startMarker = new T.Marker(result.getStart(), {
        icon: new T.Icon({
          iconUrl: stanet,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(startMarker);
      var endMarker = new T.Marker(result.getEnd(), {
        icon: new T.Icon({
          iconUrl: endnet,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(endMarker);
      if (result.results.mid) {
        let routeIfo = result.results.mid.split(";");
        for (let index = 0; index < routeIfo.length; index++) {
          let mid = routeIfo[index].split(',');
          let Marker = new T.LngLat(mid[0], mid[1]);
          var midMarker2 = new T.Marker(Marker, {
            icon: new T.Icon({
              iconUrl: tuji,
              iconSize: new T.Point(25, 34),
              iconAnchor: new T.Point(15, 35),
            }),
          });
          map.addOverLay(midMarker2);
        }
      }
    }
    const searchResult = (result) => {
      //添加起始点
      createStartMarker(result);
      let obj = result;
      //获取方案个数
      var routes = result.getNumPlans();
      for (var i = 0; i < routes; i++) {
        //获得单条驾车方案结果对象
        var plan = result.getPlan(i);
        //显示驾车线路
        createRoute(plan.getPath());
        //显示最佳级别
        map.setViewport(plan.getPath());
      }
    }
    return {
      onLoad, createRoute, createStartMarker, searchResult
    }
  },
}
</script>
<style>
#mapDiv {
  width: 100%;
  height: 25rem;
}
</style>