/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:16:50
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

/* 车型配置查询 */
export const postcar = (params) => { 
    return request.apiGet(`/api${API_VERSION}/Delivery/DeliveryCarType/GetPage`, params)
}
/* 车型配置添加 */
export const postcaradd = (params) => { 
    return request.apiPost(`/api${API_VERSION}/Delivery/DeliveryCarType/Add`, params)
}
/* 删除车型配置 */
export const deletecar = (params) => { 
    return request.apiDelete(`/api${API_VERSION}/Delivery/DeliveryCarType/SoftDelete?carId=` + params)
}
/* 批量删除车型配置 */
export const deletecardele = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Delivery/DeliveryCarType/BatchSoftDelete`, params)
}
/* 车型配置修改 */
export const putcarupdat = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Delivery/DeliveryCarType/Update`, params)
}
/* 获取所有可用的车型 */
export const DeliveryCarTypeGetCarType=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Delivery/DeliveryCarType/GetCarType`, params)
}