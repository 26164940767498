<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-05-24 10:24:20
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:15:59
-->
<template>
    <div>
        <!-- 面包屑 begin -->
        <eup-crumbs icon="ScaleToOriginal" firstCrumbs="运费管理" secondCrumbs="车型配置" />
        <!-- 面包屑 end -->

        <!-- 内容区域 begin -->
        <div class="container">
            <div class="handle-box">
              <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:GetPage`,`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:Add`,`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:BatchSoftDelete`])">
                <template v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:GetPage`])">
                  <el-form-item label="车辆类型">
                    <el-input v-model="state.query.carName" placeholder="车辆类型" ></el-input>
                  </el-form-item>     
                  <el-form-item label="是否有效">
                    <el-select v-model="state.query.isActive" placeholder="是否有效" class="handle-select mr10" @change="isActiveHandle">
                      <el-option v-for="item in state.queryOpisActive" :key="item.isActive" :label="item.label" :value="item.isActive"></el-option>
                    </el-select>
                  </el-form-item>         
                </template>
                <el-form-item>
                <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
                <el-button type="primary" icon="Plus" @click="handleClickAddData" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:Add`])">新增</el-button>
                <el-button
                    type="danger"
                    icon="Delete"
                    class="handle-del mr10"
                    @click="handleClickBatchDelete(this)"
                    v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:BatchSoftDelete`])"
                >批量删除</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-table
                :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading"
            >
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="carName" label="车辆类型" min-width="100" align="center"></el-table-column>
                <el-table-column prop="carImgUrl" label="车型图片" min-width="120" align="center">
                <template min-width="90" style="display:block;" #default="scope">
                  <el-image style="width:80px;height:80px;border:none;" :src="scope.row.carImgUrl"/>
                </template>
                </el-table-column>
                <el-table-column prop="loadWeight" label="装载重量/kg" min-width="120" align="center"></el-table-column>
                <el-table-column prop="loadVolume" label="装载体积/m³" min-width="120" align="center"></el-table-column>
                <el-table-column prop="loadSize" label="装载尺寸/m" min-width="120" align="center"></el-table-column>
                <el-table-column prop="isActive" label="是否有效" min-width="100" align="center" :formatter="hasValidFormat"></el-table-column>
                <el-table-column prop="remark" min-width="160" label="备注" align="center"></el-table-column>
                <el-table-column label="操作" min-width="180" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:Update`,`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:SoftDelete`])">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="Edit"
                            @click="handleEdit(scope.$index,scope.row)"
                            v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:Update`])"
                        >编辑</el-button>
                        <el-button
                            type="text"
                            icon="Delete"
                            class="red"
                            @click="handleClickDelete(scope.row, cb_Delete_Func, this)"
                            v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryCarType:SoftDelete`])"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->

        <!-- 添加/编辑窗口 begin -->
        <el-dialog 
          :title="state.dialogTitle"
          v-model="state.addDialogFormVisible"
          :close-on-click-modal="false"
          width="60%"
          @close="closeEditForm(this)">
          <el-form
            ref="addForm"
            :model="state.addForm"
            :rules="state.addFormRules"
            label-width="100px"
            :inline="false"
          >
            <el-row>
              <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18"> -->
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="车辆类型" prop="carName">
                    <el-input v-model="state.addForm.carName" autocomplete="off"  />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="装载重量" prop="loadWeight">
                    <el-input v-model="state.addForm.loadWeight" maxlength="8" > <template #append>kg</template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="装载体积" prop="loadVolume">
                    <el-input  v-model="state.addForm.loadVolume" maxlength="8"> <template #append>m³</template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="装载尺寸" prop="loadSize">
                    <el-input v-model="state.addForm.loadSize" maxlength="15"> <template #append>m</template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="是否有效" prop="isActive">
                    <el-select v-model="state.addForm.isActive" placeholder="请选择">
                          <el-option
                            v-for="item in state.opisActive"
                            :key="item.isActive"
                            :label="item.label"
                            :value="item.isActive"
                          ></el-option
                        ></el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="排序" prop="Sort">
                    <el-input v-model="state.addForm.sort" autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="备注" prop="remark">
                    <el-input v-model="state.addForm.remark" autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                  <el-form-item label="车型图片" prop="Portrait">
                      <el-upload action="#" :on-success="haderOnsuucess" list-type="picture-card" :limit="1" :on-change="handleChange" :on-remove="handleRemove" >
                        <img v-if="state.addForm.carImgUrl" :src="state.addForm.carImgUrl" style="width:100%;height:100%">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload> 
                  </el-form-item>
                </el-col>
              <!-- </el-col> -->
            </el-row>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="state.addDialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="addDialogFormSave(this)">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 添加/编辑窗口 end -->
    </div>
</template>

<script>
import {reactive,onMounted} from "vue"
import { ElMessage } from 'element-plus'
import { postcar, postcaradd, putcarupdat,deletecar,deletecardele} from "@/serviceApi/freight/vehicleConfig";
import {postimg} from "@/serviceApi/Image/Image"
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
    name: "VehicleConfig",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
    setup(){
        const state = reactive({
          VIEW_VERSION: VIEW_VERSION,
            query: {
                carName: "",
                isActive: "0"
            },
            pageIndex: 1,
            pageSize: 10,
            tableData: [],
            multipleSelection: [],
            delList: [],
            pageTotal: 0,
            frequency:0,
            form: {},
            idx: -1,
            loading: false,
            addDialogFormVisible: false,
            dialogTitle: "",
            // 新增/编辑界面数据
            addForm: {carName: "",loadWeight: 0,carImg: "",loadVolume: "",isActive: '1',sort:"0" ,remark: "", loadSize: "",carId:"",carImgUrl:""},
            //是否有效枚举集（查）
            queryOpisActive: [{isActive: '0',label: '全部'},{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
            //是否有效枚举集
            opisActive: [{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
            //新增校验
            addFormRules: {
              carName: [
                { 
                  required: true, message: '请输入车辆类型', trigger: 'blur' }
                ],
              loadWeight: [
                { 
                  required: true, message: '请输入装载重量', trigger: 'blur' }
              ],
              loadVolume: [
                { 
                  required: true, message: '请输入装载体积', trigger: 'blur' }
              ],
              loadSize: [
                { 
                  required: true, message: '请输入装载尺寸', trigger: 'blur' }
              ],
              sort: [
                { 
                  required: true, message: '请输入排序', trigger: 'blur' }
              ],
            },
            hideUpload: false,
            limitCount: 1,
            operType_img_show: true,//编辑页面显示图片img标签
            operType_imgicon_plus: true,
            operType: 0, //0:添加  1:编辑
        });
        onMounted(()=>{
            getData();
        })
        //获取表单信息
        const getData=()=>{
            var params = {
                "currentPage": state.pageIndex,
                "pageSize": state.pageSize,
                "filter.carName": state.query.carName,
                "filter.isActive": state.query.isActive=='0'? null:state.query.isActive,
                "dynamicFilter": state.dynamicFilter
            }
            state.loading = true;
            postcar(params).then(function(res){
              if(res.code == 1){
                state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
              } else {
              ElMessage.error(res.msg);   
              }
              state.loading = false;
            });
        }
        // 编辑操作
        const handleEdit=(index, row)=> {
          state.dialogTitle = "编辑车型配置";
          state.addDialogFormVisible = true;
          state.operType_img_show = true;
          state.operType_imgicon_plus = false;
          state.operType = 1;
          state.addForm = {
            "carName": row.carName,
            "carImg": row.carImg,
            "carImgUrl": row.carImgUrl,
            "loadWeight": row.loadWeight,
            "loadVolume": row.loadVolume,
            "loadSize": row.loadSize,
            "sort": row.sort + "",
            "isActive": row.isActive + "",
            "remark": row.remark,
            "carId": row.carId
          };

          if (row.carImgUrl == undefined || row.carImgUrl == null || row.carImgUrl == ""){
              //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
              state.addForm.carImgUrl = "";
          } else {
              state.addForm.carImgUrl = row.carImgUrl;
          }
        }
        // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }
        //删除
        const handleClickDelete = (row, callback, t) =>{
          var id = row.carId;
          if (callback){
            callback(id, t);
          }
        }
        //批量删除
        function handleClickBatchDelete(t){
          if(state.multipleSelection.length == 0){//未选中
            ElMessage.error("请选择要删除的数据！");
          } else {
            var arrIds = state.multipleSelection.map(s =>{
              return s.carId;
            });
            t.$confirm('此操作将删除选中的记录, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              deletecardele(arrIds).then(res=>{
                if (res.code == 1){
                  ElMessage.success("操作成功！");
                  getData();
                } else {
                  ElMessage.error("操作失败！");
                }
              }).catch(err=>{
                ElMessage.error(err.msg);
              });
            }).catch(err=>{
              ElMessage.info("取消批量删除！");
            });
          }
        }
        //点击新增
        const handleClickAddData = () =>{
          state.addDialogFormVisible = true;
          state.dialogTitle = "新增车型配置";
          state.operType_img_show = false;
          state.operType_imgicon_plus = true;
          state.operType = 0;
          state.hideUpload = false;

          state.addForm.carImgUrl = "";
          //清空历史数据
          state.addForm = {
            carName: "",
            carImg: "",
            loadWeight: 0,
            loadVolume: "",
            loadSize: "",
            sort: 0,
            isActive: "1",
            remark: "",
            carId: "",
            carImgUrl: ""
          };
        }
        const hasValidFormat = ((row) => {
          if (row.isActive == 1){
            return "有效";
          } else if (row.isActive == 2){
            return "无效";
          }
        });
        //保存新增/编辑价格配置
        const addDialogFormSave = (t)=>{
          //先空判断
          if (state.addForm.carName === ""){
            ElMessage.error("车辆类型不能为空");
            return;
          }
          if (state.addForm.loadWeight === ''){
            ElMessage.error("装载重量不能为空");
            return;
          }
          if (state.addForm.loadVolume === ''){
            ElMessage.error("装载体积不能为空");
            return;
          }
          if (state.addForm.loadSize === ''){
            ElMessage.error("装载尺寸不能为空");
            return;
          }
          if (state.addForm.sort === ''){
            ElMessage.error("排序不能为空");
            return;
          }
          var params = {
              "carName": state.addForm.carName,
              "carImg": state.addForm.carImg,
              "loadWeight": state.addForm.loadWeight,
              "loadVolume": state.addForm.loadVolume,
              "loadSize": state.addForm.loadSize,
              "sort": state.addForm.sort,
              "isActive": state.addForm.isActive,
              "remark": state.addForm.remark
            };
            
          if(state.dialogTitle != "新增车型配置"){
            params.carId = state.addForm.carId;
          } 
          if (0 == state.operType) {//新增
            postcaradd(params).then(res =>{
              if (res.code == 1){
                t.$message({
                  type: 'success',
                  message: '提交成功!'
                });
                getData();
                state.hideUpload = false;
                state.addDialogFormVisible = false;
              } else {
                t.$message({
                  type: 'warning',
                  message: res.msg
                });
              }
            }).catch(err=>{
              t.$message({
                type: 'error',
                message: '提交失败！'
              });
            }); 
          } else if (1 == state.operType){//编辑
            putcarupdat(params).then(res =>{
              if (res.code == 1){
                t.$message({
                  type: 'success',
                  message: '提交成功!'
                });
                getData();
                state.hideUpload = false;
                state.addDialogFormVisible = false;
              } else {
                t.$message({
                  type: 'warning',
                  message: res.msg
                });
              }
            }).catch(err=>{
              t.$message({
                type: 'error',
                message: '提交失败！'
              });
            }); 
          }
        }

        //删除回调
        const cb_Delete_Func = function(params,t){
          t.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              deletecar(params).then(res =>{
                if (res.code == 1){
                  t.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  //刷新表单
                  getData();
                } else {
                  t.$message({
                    type: 'warning',
                    message: res.msg
                  });
                }
              }).catch(err=>{
                t.$message({
                  type: 'error',
                  message: '删除失败！'
                });
              });    
            }).catch((err) => {
              t.$message({
                type: 'info',
                message: '已取消删除'
              });
          });
        }

        function closeEditForm(t){
          t.$refs.addForm.resetFields();
        }

        //切换是否有效
        const isActiveHandle = ()=>{
          getData();
        }

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }

      //图片 begin
        //图片上传成功回调
        function haderOnsuucess(response, file, fileList) {
          console.log(response, file, fileList);
        }
        function handleRemove(file, fileList) {
          state.hideUpload = fileList.length >= state.limitCount;
          state.addForm.carImgUrl="";
        }
        function handleChange(file, fileList) {
          state.hideUpload = fileList.length >= state.limitCount;
           state.frequency +=1;
            if (state.frequency%2 === 0) {
              var formData = new FormData();
              formData.append('File', file.raw);
              formData.append('FileCategory', 'Avatar');
              postimg(formData).then(function(res) {
                 if (1 == res.code) {
                   state.addForm.carImg = res.data.eurl;
                   state.addForm.carImgUrl = res.data.url;
                 } else {
                   ElMessage.error(res.msg);
                 }
              });
            }
        }
        return {
            state,
            handleSelectionChange,
            handleSearch,
            handleEdit,
            handleClickDelete,
            handleClickBatchDelete,
            handleClickAddData,
            addDialogFormSave,
            cb_Delete_Func,
            hasValidFormat,
            closeEditForm,
            isActiveHandle,
            getData,
            resPageData,
            haderOnsuucess,
            handleRemove,
            handleChange,
        }
    }
};
</script>
<style>
</style>
