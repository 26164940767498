<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-09 10:57:03
-->
<template>
  <router-view />
</template>

<script>
export default {
  setup () {
    return {
    }
  }
}
</script>

<style>
@import './assets/css/base.css';
@import './assets/css/main.css';
@import './assets/css/color-dark.css';
@import './assets/css/form.css';
</style>
