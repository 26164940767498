import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取司机解绑列表
export const DriverApplyCancelGetPage = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Driver/DriverApplyCancel/GetPage`, params);
}
/* 司机申请解绑详情 */
export const DriverApplyCancelGet = (params) => { 
    return request.apiGet(`/api${API_VERSION}/Driver/DriverApplyCancel/Get?applyId=${params}`)
}
//司机解绑审核
export const updateDriverApplyCancel = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Driver/DriverApplyCancel/UpdateApplyApproval`, params)
}