<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs firstCrumbs="用户管理"
                secondCrumbs="用户地址"
                icon="User" />
    <!-- 面包屑 end -->

    <!-- 内容区域 begin -->
    <div class="container">
      <div class="handle-box">
        <el-form ref="state.query"
                 :model="query"
                 :inline="true"
                 v-if="checkPermission([`api${VIEW_VERSION}:user:address:getpage`])">
          <el-form-item label="用户名/手机">
            <el-select v-model="query.userId"
                       filterable
                       placeholder="请输入"
                       remote
                       :remote-method="filterHandle"
                       :clearable="true">
              <el-option v-for="item in userOptions"
                         :key="item.userId"
                         :label="item.nickName"
                         :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货人姓名">
            <el-input v-model="query.receiveName"
                      placeholder="收货人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="query.phone"
                      placeholder="手机号码"></el-input>
          </el-form-item>
          <el-form-item style="margin-right: 10px">
            <el-button type="primary"
                       icon="Search"
                       @click="handleSearch">搜索</el-button>
          </el-form-item>
          <el-form-item style="margin-right: 10px">
            <el-button type="primary"
                       icon="Plus"
                       @click="handleClickAddData"
                       v-if="checkPermission([`api${VIEW_VERSION}:user:address:add`])">
              新增
            </el-button>
          </el-form-item>
          <el-form-item style="margin-right: 10px">
            <el-button type="danger"
                       icon="Delete"
                       class="handle-del mr10"
                       @click="handleClickBatchDelete()"
                       v-if="
                checkPermission([`api${VIEW_VERSION}:user:address:batchdelete`])
              ">
              批量删除
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="loading">
        <el-table-column type="selection"
                         width="60"
                         align="center"></el-table-column>
        <el-table-column prop="userName"
                         label="用户名"
                         min-width="130"
                         align="center">
        </el-table-column>
        <el-table-column prop="addressType"
                         label="地址类型"
                         min-width="60"
                         align="center"
                         :formatter="addressTypeFormat">
        </el-table-column>
        <el-table-column prop="receiveName"
                         label="收货人姓名"
                         min-width="130"
                         align="center">
        </el-table-column>
        <el-table-column prop="detailAddress"
                         label="地址详情"
                         min-width="250"
                         align="center"
                         :formatter="detailAddressFormat"></el-table-column>
        <el-table-column prop="phone"
                         label="手机号码"
                         min-width="130"
                         align="center"></el-table-column>
        <el-table-column label="操作"
                         min-width="180"
                         align="center"
                         fixed="right">
          <template #default="scope">
            <el-button v-if="checkPermission([`api${VIEW_VERSION}:user:address:update`])"
                       type="text"
                       icon="Edit"
                       @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text"
                       icon="Delete"
                       class="red"
                       @click="handleClickDelete(scope.row)"
                       v-if="checkPermission([`api${VIEW_VERSION}:user:address:delete`])">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination :current-page="pageIndex"
                     :pagesizes="[10, 20, 50, 100]"
                     :pagesize="pageSize"
                     :total="pageTotal"
                     @getPageData="getData"
                     @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 内容区域 end -->
    <!-- 添加/编辑窗口 begin -->
    <el-dialog :title="dialogTitle"
               v-model="addDialogFormVisible"
               :close-on-click-modal="true"
               width="60%"
               @close="closeEditForm()">
      <el-form ref="add_form"
               :model="addForm"
               :rules="addFormRules"
               label-width="100px"
               :inline="false">
        <el-row>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6"
                  v-if="isEditDialogType">
            <el-form-item label="用户名/手机"
                          prop="userId">
              <el-select v-model="addForm.userId"
                         filterable
                         placeholder="请输入用户名/手机"
                         :remote-method="filterHandle"
                         remote
                         @clear="onClearDialog"
                         :clearable="true">
                <el-option v-for="item in userOptions"
                           :key="item.userId"
                           :label="item.nickName"
                           :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="收货人"
                          prop="receiveName">
              <el-input v-model="addForm.receiveName"
                        placeholder="请输入收货人姓名"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="addForm.phone"
                        placeholder="请输入手机号"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="地址类型">
              <el-select v-model="addForm.addressType"
                         placeholder="地址类型">
                <el-option v-for="item in addressType"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="24"
                  :md="16"
                  :lg="16"
                  :xl="18">
            <el-form-item label="省市地区"
                          prop="fullArrId">
              <el-cascader :key="addFormKey"
                           v-model="addForm.fullArrId"
                           :clearable="true"
                           ref="fullCascader"
                           :props="optionProps"
                           style="width: 100%">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="12"
                  :xl="12">
            <el-form-item label="详细地址"
                          prop="detailAddress">
              <el-input v-model="addForm.detailAddress"
                        placeholder="请输入详细地址"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="12"
                  :xl="12">
            <el-form-item label="地址名称"
                          prop="addressName">
              <el-input v-model="addForm.addressName"
                        placeholder="请输入地址名称"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeEditForm()">取 消</el-button>
          <el-button type="primary"
                     @click="addDialogFormSave(this)">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 end -->
  </div>
</template>
<script>
import { reactive, onMounted, toRefs, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { getPageAddressList, DeleteUserddress, BatchDeleteUserddress, AddUserddress, updateUserAddress, getAddressInfo } from "@/serviceApi/user/Address"
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue";
import EnumConfig from "@/enum/EnumConfig";
import {
  elConfirmDialog,
  IsNullOrEmpty,
  isValidMobile,
} from "@/common/js/comm";
import { getUserInfo } from "@/serviceApi/permission/userRole";
import { getSelectList } from "@/serviceApi/system/areaManage";
let validatePhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号"));
  }
  //使用正则表达式进行验证手机号码
  else if (!isValidMobile(value)) {
    callback(new Error("手机号不正确"));
  } else {
    callback();
  }
};
var CURR_VIEW_VERSION =
  EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == "V0" ? "" : `:S:${CURR_VIEW_VERSION}`;

export default {
  name: "UserAddress",
  components: {
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs,
  },
  setup () {
    const fullCascader = ref(null);
    const add_form = ref(null);
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      query: {
        userId: "",
        phone: "",
        receiveName: "",
      },
      modules: [],
      cascadervalue: "",
      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      multipleSelection: [],
      pageTotal: 0,
      addFormKey: 1,
      loading: false,
      userOptions: [],
      timeoutId: null,
      addDialogFormVisible: false,
      dialogTitle: "",
      isEditDialogType: false, //默认是编辑对话框，true是新增对话框
      addressType: [
        { value: 1, label: "发货" },
        { value: 2, label: "收货" },
      ],
      //新增/编辑数据
      addForm: {
        addressId: "",
        userId: "",
        regionId: "",
        phone: "",
        fullArrId: [],
        receiveName: "",
        baseAddress: "",
        detailAddress: "",
        addressType: 1,
        isDefault: 2,
      },
      dialogType: 0, //对话框类型, 0:编辑框  1:新增框
      addFormRules: {
        detailAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          { max: 50, message: "详细地址长度不能超过50字", trigger: "blur" },
        ],
        addressName: [
          { required: true, message: "请输入地址名称", trigger: "blur" },
          { max: 50, message: "地址名称长度不能超过50字", trigger: "blur" },
        ],
        receiveName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        userId: [
          { required: true, message: "请输入选择用户", trigger: "change" },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        fullArrId: [
          {
            required: true,
            type: "array",
            message: "请选择地区",
            trigger: "change",
          },
        ],
      },
      optionProps: {
        value: "regionId",
        label: "regionName",
        checkStrictly: false,
        emitPath: true,
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value } = node; // 获取当前node对象中的level, value属性
          getRegionList(value, level, resolve);
        },
      },
    });
    onMounted(() => {
      getData();
    });
    //获取表单信息
    const getData = () => {
      var params = {
        currentPage: state.pageIndex,
        pageSize: state.pageSize,
        "Filter.ReceiveName": state.query.receiveName,
        "Filter.Phone": state.query.phone,
        "Filter.UserId": state.query.userId,
      };
      state.loading = true;
      getPageAddressList(params).then(function (res) {
        if (res.code == 1) {
          state.pageTotal = res.data.total; //初始化列表数据总数
          state.tableData = res.data.list;
          //添加num序号字段
          state.tableData.forEach((data, i) => {
            data.num = i + 1;
          });
        } else {
          ElMessage.error(res.msg);
        }
        state.loading = false;
      });
    };
    //获取地区
    const getSelectList_Area = (regionId, callback) => {
      getSelectList(regionId)
        .then((res) => {
          if (res.code == 1) {
            if (callback) {
              callback(res.data);
            }
          } else {
            ElMessage.error(res.msg);
          }
        })
        .catch((err) => {
          ElMessage.error(err.msg);
        });
    };
    const getRegionList = (id, level, resolve) => {
      id = IsNullOrEmpty(id) ? "0" : id;
      getSelectList_Area(id, (data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            // 当点击最后一级的时候 label 后面不会转圈圈 并把相关值赋值到选择器上
            if (!item.isSubordinate) {
              item.leaf = true;
            }
          });
        }
        if (!IsNullOrEmpty(resolve)) {
          resolve(data);
        }
      });
    };
    // 触发搜索按钮
    const handleSearch = () => {
      state.pageIndex = 1;
      getData();
    };
    // 多选操作
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((a) => {
        return a.addressId;
      });
    };
    const detailAddressFormat = (row) => {
      return row.baseAddress.replace(/[|]/g, "") + " " + row.detailAddress;
    };
    const addressTypeFormat = (row) => {
      if (row.addressType == 1) {
        return "发货";
      } else if (row.addressType == 2) {
        return "收货";
      }
    };
    /**
     * @description 删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickDelete = (row) => {
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          DeleteUserddress(row.addressId).then((res) => {
            if (res.code == 1) {
              ElMessage.success("删除成功!");
              getData();
            } else {
              ElMessage.error(res.msg);
            }
          });
        }
      );
    };

    /**
     * @description 批量删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickBatchDelete = () => {
      if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          BatchDeleteUserddress(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                getData();
              } else {
                ElMessage.error("删除失败!");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除!");
        }
      );
    };
    //关闭对话框
    const closeEditForm = () => {
      state.addDialogFormVisible = false;
      add_form.value.resetFields(); //清空表单数据
    };
    //新增
    const handleClickAddData = () => {
      ++state.addFormKey;
      state.addDialogFormVisible = true;
      state.isEditDialogType = true;
      state.dialogType = 1;
      state.dialogTitle = "新增用户地址";
      state.addForm = {
        addressId: "",
        userId: "",
        regionId: "",
        phone: "",
        fullArrId: [],
        receiveName: "",
        baseAddress: "",
        detailAddress: "",
        addressType: 1,
        isDefault: 2,
      };
    };
    //编辑
    const handleEdit = (index, row) => {
      ++state.addFormKey;
      state.addDialogFormVisible = true;
      state.dialogTitle = "编辑地区";
      state.isEditDialogType = false;
      state.dialogType = 0;
      var params = {
        addressId: row.addressId,
      };
      getAddressInfo(params).then((res) => {
        if (res.code == 1) {
          res.data.fullArrId = res.data.fullId.split(",").map((item) => {
            return item;
          });
          res.data.fullArrId.push(res.data.regionId);
          state.addForm = res.data;
          state.addForm.addressId = row.addressId
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    /**
     * @description 确认保存
     * @author lja
     */
    const addDialogFormSave = () => {
      add_form.value.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if ((state.addForm.fullArrId.length <= 0 || fullCascader.value.getCheckedNodes().length <= 0) && state.dialogType == 1) {
          ElMessage.error("请选择地区");
          return;
        }
        if (fullCascader.value.getCheckedNodes()[0]) {
          state.addForm.baseAddress = fullCascader.value
            .getCheckedNodes()[0]
            .text.replace(/[/]/g, "|")
            .split(" ")
            .join("");
        }
        let base = state.addForm.baseAddress.split("|");
        if (base.length == 3) {
          let baseAddress = "";
          for (let index = 0; index < base.length; index++) {
            if (base.length - index != 1) {
              baseAddress += base[index] + "|";
            } else {
              baseAddress += base[index];
            }
            if (index == 1) {
              baseAddress += "|";
            }
          }
          state.addForm.baseAddress = baseAddress;
        }
        state.addForm.regionId = state.addForm.fullArrId[state.addForm.fullArrId.length - 1];
        switch (state.dialogType) {
          case 1://新增
            AddUserddress(state.addForm).then((res) => {
              if (res.code == 1) {
                ElMessage.success("添加成功");
                state.addDialogFormVisible = false;
                getData();
              } else {
                ElMessage.error(res.msg);
              }
            });
            break;
          case 0://编辑
            updateUserAddress(state.addForm).then((res) => {
              if (res.code == 1) {
                ElMessage.success("修改成功");
                state.addDialogFormVisible = false;
                getData();
              } else {
                ElMessage.error(res.msg);
              }
            });
            break;
          default:
            break;
        };
      });
    };
    /**
     * @description 清空编辑或者添加对话框的查询条件
     * @author weig
     */
    const onClearDialog = () => {
      state.userOptions.length = 0;
    };
    //模糊查询
    const filterHandle = (value) => {
      if (IsNullOrEmpty(value)) {
        return;
      }
      let params = {
        name: value,
      };
      if (params.name.trim() == "") {
        state.userOptions = [];
        return;
      }
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
        state.timeoutId = null;
      }
      state.timeoutId = setTimeout(() => {
        getUserInfo(params.name).then(function (res) {
          if (res.code == 1) {
            state.userOptions = [];
            if (res.data.user.length > 0) {
              res.data.user.forEach((item) => {
                state.userOptions.push({
                  userId: item.userId,
                  nickName: item.nickName,
                });
              });
            }
          } else {
            ElMessage.error(res.msg);
          }
          state.loading = false;
        });
      }, 300);
    };
    /**
     * @description 子组件返回分页数据
     * @author weig
     * @param {Object} obj
     */
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    };
    return {
      ...toRefs(state),
      handleSelectionChange,
      handleSearch,
      getData,
      resPageData,
      detailAddressFormat,
      addressTypeFormat,
      handleClickDelete,
      filterHandle,
      handleClickBatchDelete,
      handleClickAddData,
      closeEditForm,
      add_form,
      addDialogFormSave,
      handleEdit,
      fullCascader,
      onClearDialog,
    };
  },
};
</script>
<style scoped>
</style>
