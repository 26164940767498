import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取用户配置
export const GetPageUserConfigList = (params)=>{
    return request.apiGet(`/api${API_VERSION}/User/UserConfig/GetPage`, params);
}
//修改用户配置
export const UpdateUserConfig = (params)=>{
    return request.apiPut(`/api${API_VERSION}/User/UserConfig/Update`, params);
}
//添加用户配置
export const AddUserConfig = (params)=>{
    return request.apiPost(`/Api${API_VERSION}/User/UserConfig/Add`, params);
}
//删除用户配置
export const SoftDeleteUserConfig = (params)=>{
    return request.apiDelete(`/Api${API_VERSION}/User/UserConfig/Delete`,params);
}
//批量删除用户配置
export const BatchSoftDeleteUserConfig = (params)=>{
    return request.apiPut(`/Api${API_VERSION}/User/UserConfig/BatchDelete`,params);
}