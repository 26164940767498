/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:15:48
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取报价配置信息列表
export const getPriceConfigList = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Delivery/DeliveryPriceCalcRule/GetPage`, params);
}
/* 添加价格配置 */
export const postpriceadd = (params) => { 
    return request.apiPost(`/api${API_VERSION}/Delivery/DeliveryPriceCalcRule/Add`, params)
}
//编辑价格配置
export const putpudeaprice = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Delivery/DeliveryPriceCalcRule/Update`, params)
}
//删除价格配置
export const detletpeice = (params) => { 
    return request.apiDelete(`/api${API_VERSION}/Delivery/DeliveryPriceCalcRule/SoftDelete?id=` + params)
}
//批量删除价格配置
export const putdelectprice = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Delivery/DeliveryPriceCalcRule/BatchSoftDelete`, params)
}