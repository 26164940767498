/*
    地图扩展组件
    by:lzc
    date:2019-07-18
    v:20180718
*/
//计算两坐标之间距离(米) co {lat:23.02067,lng:113.75179}
export function GetDistance(loc1,loc2){function getRad(d){return d*Math.PI/180}var f=getRad((loc1.lat+loc2.lat)/2);var g=getRad((loc1.lat-loc2.lat)/2);var l=getRad((loc1.lng-loc2.lng)/2);var sg=Math.sin(g);var sl=Math.sin(l);var sf=Math.sin(f);var dO=6378137;var fl=1/298.257;sg=sg*sg;sl=sl*sl;sf=sf*sf;var s=sg*(1-sl)+(1-sf)*sl;var c=(1-sg)*(1-sl)+sf*sl;var w=Math.atan(Math.sqrt(s/c));var r=Math.sqrt(s*c)/w;var d=2*w*dO;var h1=(3*r-1)/2/c;var h2=(3*r+1)/2/s;return d*(1+fl*(h1*sf*(1-sg)-h2*(1-sf)*sg))}
//百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
export function BD09ToGCJ02(bdLat,bdLng){var xPi=3.141592653589793*3000/180;var y=bdLat-0.006;var x=bdLng-0.0065;var z=Math.sqrt(x*x+y*y)-0.00002*Math.sin(y*xPi);var theta=Math.atan2(y,x)-0.000003*Math.cos(x*xPi);var lat=z*Math.sin(theta);var lng=z*Math.cos(theta);return{lat:lat,lng:lng}}
//火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
export function GCJ02ToBD09(lat,lng){var xPi=3.141592653589793*3000/180;var z=Math.sqrt(lng*lng+lat*lat)+0.00002*Math.sin(lat*xPi);var theta=Math.atan2(lat,lng)+0.000003*Math.cos(lng*xPi);var bdLat=z*Math.sin(theta)+0.006;var bdLng=z*Math.cos(theta)+0.0065;return{lat:bdLat,lng:bdLng}};

const x_PI = 3.14159265358979324 * 3000.0 / 180.0
const PI = 3.1415926535897932384626
const a = 6378245.0
const ee = 0.00669342162296594323
/**
 * GCJ02（火星坐标系） 转换为 WGS84 / 即谷歌高德转WGS84
 * @param { Number } lng 
 * @param { Number } lat 
 */
 export function gcj02towgs84 (lng, lat) {
  if (outOfChina(lng, lat)) {
    return [lng, lat]
  }
  else {
    var dlat = transformlat(lng - 105.0, lat - 35.0)
    var dlng = transformlng(lng - 105.0, lat - 35.0)
    var radlat = lat / 180.0 * PI
    var magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    var sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
    const mglat = lat + dlat
    const mglng = lng + dlng
    return [lng * 2 - mglng, lat * 2 - mglat]
  }
}

/**
 * 判断是否在国内，不在国内则不做偏移
 * @param {*} lng 
 * @param {*} lat 
 */
export function outOfChina (lng, lat) {
  return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
}


/**
 * 纬度转换
 * @param { Number } lng 
 * @param { Number } lat 
 */
export function transformlng (lng, lat) {
  var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
  ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
  return ret
 }


/**
 * 经度转换
 * @param { Number } lng 
 * @param { Number } lat 
 */
export function transformlat (lng, lat) {
  var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
  ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
  return ret
}