<template>
    <div class="SysHome" >
        <div ref="ApacheMy" class="ApacheTxt"></div>
        <el-row :gutter="20">
 <el-col :span="14">
    <div ref="Particle" class="WebsiteParticle"></div>
    </el-col>
    <el-col :span="10">
        <div ref="WebsiteMy" class="WebsiteRide"></div>
    </el-col>
  </el-row>
        <div ref="SysHome" class="chart"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {onMounted,ref ,reactive, toRefs} from 'vue';
export default {
    setup() {
        const state=reactive({

        });
        let SysHome=ref();
        let ApacheMy=ref();
        let WebsiteMy=ref();
        let Particle=ref();
        const echartInit=()=>{
          let myChart = echarts.init(SysHome.value);
const categories = (function () {
  let now = new Date();
  let res = [];
  let len = 10;
  while (len--) {
    res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''));
    now = new Date(+now - 100000);
  }
  return res;
})();
const categories2 = (function () {
  let res = [];
  let len = 10;
  while (len--) {
    res.push(10 - len - 1);
  }
  return res;
})();
const data = (function () {
  let res = [];
  let len = 10;
  while (len--) {
    res.push(Math.round(Math.random() * 100000));
  }
  return res;
})();
const data2 = (function () {
  let res = [];
  let len = 0;
  while (len < 10) {
    res.push(+(Math.random() * 10 +3).toFixed(1));
    len++;
  }
  return res;
})();
 let option = {
  title: {
    text: '订单统计'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#283b56'
      }
    }
  },
  legend: {},
  toolbox: {
    show: true,
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  dataZoom: {
    show: false,
    start: 0,
    end: 100
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: true,
      data: categories
    },
    {
      type: 'category',
      boundaryGap: true,
      data: categories2
    }
  ],
  yAxis: [
    {
      type: 'value',
      scale: true,
      name: 'Price',
      max: 30,
      min: 0,
      boundaryGap: [0.2, 0.2]
    },
    {
      type: 'value',
      scale: true,
      name: 'Order',
      max: 1200,
      min: 0,
      boundaryGap: [0.2, 0.2]
    }
  ],
  series: [
    {
      name: '订单金额',
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      data: data
    },
    {
      name: '接单数量',
      type: 'line',
      data: data2
    }
  ]
};
let app={};
app.count = 11;
setInterval(function () {
  let axisData = new Date().toLocaleTimeString().replace(/^\D*/, '');
  data.shift();
  data.push(Math.round(Math.random() * 10000));
  data2.shift();
  data2.push(+(Math.random() * 10 + 5).toFixed(1));
  categories.shift();
  categories.push(axisData);
  categories2.shift();
  categories2.push(app.count++);
  myChart.setOption({
    xAxis: [
      {
        data: categories
      },
      {
        data: categories2
      }
    ],
    series: [
      {
        data: data
      },
      {
        data: data2
      }
    ]
  });
}, 10000);

option && myChart.setOption(option);
           myChart.setOption(option);
        };
       const  Apache=()=>{
    let ApacheMyChart = echarts.init(ApacheMy.value);
        let  ApacheOption = {
   graphic: {
    elements: [
      {
        type: 'text',
        left: 'center',
        top: 'center',
        style: {
          text: '易运之家后台管理',
          fontSize: 40,
          fontWeight: 'bold',
          lineDash: [0, 200],
          lineDashOffset: 0,
          fill: 'transparent',
          stroke: '#1a8cfe',
          lineWidth: 1
        },
        keyframeAnimation: {
          duration: 5000,
          loop: true,
          keyframes: [
            {
              percent: 0.7,
              style: {
                fill: 'transparent',
                lineDashOffset: 200,
                lineDash: [200, 0]
              }
            },
            {
              // Stop for a while.
              percent: 0.8,
              style: {
                fill: 'transparent'
              }
            },
            {
              percent: 1,
              style: {
                fill: '#1a8cfe'
              }
            }
          ]
        }
      }
    ]
  }
};
 ApacheMyChart.setOption(ApacheOption);
       }
      const  Website=()=>{
     let WebsiteOp=echarts.init(WebsiteMy.value);
   let WebsiteOption = {
    tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name:'成交数量' },
        { value: 735, name: '接单数量' },
        { value: 580, name: '订单数量' },
        { value: 484, name: '司机数量' },
        { value: 300, name: '取消订单' }
      ]
    }
  ]
};
 WebsiteOp.setOption(WebsiteOption);
      }
      const edgesParticle=()=>{
       let edgParticle = echarts.init(Particle.value);
       const edgesPart= {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [10, 50, 80, 150, 175, 200, 260],
      type: 'line'
    }
  ]
};

 edgParticle.setOption(edgesPart);
      };
    onMounted(()=>{
          echartInit();
          Apache();
          Website();
          edgesParticle();
        });
        return{
            ...toRefs(state),echartInit,SysHome,Apache,ApacheMy,Website,WebsiteMy,edgesParticle,Particle
        }
    },
}
</script>
<style>
.ApacheTxt{
    width: auto;
    height: 60px;
}
.WebsiteRide{
  width: auto;
 height: 250px;  
}
.WebsiteParticle{
 width: auto;
 height: 250px; 
}
.chart{
 width: 98%;
 height: 350px;
 position: absolute;
 bottom: 0;
}
</style>