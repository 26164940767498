<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-05-24 10:24:20
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:15:36
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Van"
                firstCrumbs="司机管理"
                secondCrumbs="司机列表" />
    <!-- 面包屑 end -->

    <!-- 内容区域 begin -->
    <div class="container">
      <div class="handle-box">
        <el-form ref="state.query"
                 :model="state.query"
                 :inline="true"
                 v-if="checkPermission([`api${state.VIEW_VERSION}:Driver:DriverInfo:GetPage`])">
          <el-form-item label="司机类型">
            <el-select v-model="state.query.driverType"
                       placeholder="司机类型"
                       class="handle-select mr10"
                       @change="driverTypeHandle">
              <el-option v-for="item in state.optionstypede"
                         :key="item.DriverTypede"
                         :label="item.label"
                         :value="item.DriverTypede"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机状态">
            <el-select v-model="state.query.driverStatus"
                       placeholder="司机状态"
                       class="handle-select mr10"
                       @change="driverStatusHandle">
              <el-option v-for="item in state.optionse"
                         :key="item.valuee"
                         :label="item.label"
                         :value="item.valuee"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机姓名">
            <el-input v-model="state.query.realName"
                      placeholder="司机姓名"></el-input>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="state.query.licensePlate"
                      placeholder="车牌号"></el-input>
          </el-form-item>
          <el-form-item style="margin-right:10px">
            <el-button type="primary"
                       icon="Search"
                       @click="handleSearch">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading">
        <el-table-column prop="num"
                         label="序号"
                         width="60"
                         align="center"></el-table-column>
        <el-table-column prop="nickName"
                         label="昵称"
                         min-width="140"
                         align="center"></el-table-column>
        <el-table-column prop="userName"
                         label="用户名"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="realName"
                         label="司机姓名"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="carName"
                         label="车型名称"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="licensePlate"
                         label="车牌号"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="driverType"
                         label="司机类型"
                         min-width="100"
                         :formatter="hasBackFormat"
                         align="center"></el-table-column>
        <el-table-column prop="balance"
                         label="账户余额"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="driverScore"
                         label="司机评分"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="credit"
                         label="信用度"
                         min-width="80"
                         align="center"></el-table-column>
        <el-table-column prop="driverStatus"
                         label="状态"
                         min-width="80"
                         align="center">
          <template #default="{row}">
            <el-tag :type="row.driverStatus==1 ? 'success' : 'danger'"
                    disable-transitions>{{ row.driverStatus==1 ? '正常' : '锁定' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createDate"
                         label="创建时间"
                         min-width="160"
                         align="center"></el-table-column>
        <el-table-column label="操作"
                         min-width="240"
                         align="center"
                         fixed="right"
                         v-if="checkPermission([`api${state.VIEW_VERSION}:Driver:DriverInfo:Update`,`api${state.VIEW_VERSION}:Driver:DriverInfo:Get`,`api${state.VIEW_VERSION}:Driver:DriverInfo:UpdateSysCancelDriver`])">
          <template #default="scope">
            <el-button type="text"
                       icon="Edit"
                       @click="handleClickEdit(scope.$index, scope.row)"
                       v-if="checkPermission([`api${state.VIEW_VERSION}:Driver:DriverInfo:Update`])">编辑</el-button>
            <el-button type="text"
                       icon="Check"
                       @click="handleClickDetails(scope.row)"
                       v-if="checkPermission([`api${state.VIEW_VERSION}:Driver:DriverInfo:Get`])">查看详情</el-button>
            <el-button type="text"
                       icon="Delete"
                       class="red"
                       @click="handleClickDelete(scope.row)"
                       v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverinfo:updatesyscanceldriver`])">注销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination :current-page="state.pageIndex"
                     :pagesizes="[10,20,50,100]"
                     :pagesize="state.pageSize"
                     :total="state.pageTotal"
                     @getPageData="getData"
                     @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 内容区域 end -->

    <!-- 详情资料窗口 begin -->
    <el-dialog title="司机详情信息"
               :close-on-click-modal="false"
               width="60%"
               v-model="state.detailsVisible">
      <el-tabs v-model="state.activeName2">
        <el-tab-pane label="基础信息"
                     name="first">
          <section style="padding:24px 48px 74px 24px;">
            <el-form ref="detailsBaseForm"
                     :model="state.detailsDriverInfo.baseInfo"
                     label-width="100px"
                     :inline="false">
              <el-row>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机姓名"
                                prop="realName">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.realName"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机类型"
                                prop="driverType">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.driverType"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机状态"
                                prop="driverStatus">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.driverStatus"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="账户余额"
                                prop="balance">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.balance"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="信用度"
                                prop="credit">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.credit"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机评分"
                                prop="driverScore">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.driverScore"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="商家绑定"
                                prop="bindStoreNo">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.bindStoreNo "
                              autocomplete="off"
                              disabled />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="店铺名称"
                                prop="bindStoreName">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.bindStoreName"
                              autocomplete="off"
                              disabled />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="交易费率"
                                prop="transactionRate">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.transactionRate"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </section>
        </el-tab-pane>
        <el-tab-pane label="认证信息"
                     name="second">
          <section style="padding:24px 48px 74px 24px;">
            <el-form ref="detailsAuthForm"
                     :model="state.detailsDriverInfo.authInfo"
                     label-width="100px"
                     :inline="false">
              <el-row>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="真实姓名"
                                prop="realName2">
                    <el-input v-model="state.detailsDriverInfo.authInfo.realName2"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证号"
                                prop="idCardNo">
                    <el-input v-model="state.detailsDriverInfo.authInfo.idCardNo"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车牌号"
                                prop="licensePlate">
                    <el-input v-model="state.detailsDriverInfo.authInfo.licensePlate"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车辆类型"
                                prop="driverType">
                    <el-select v-model="state.CarType"
                               placeholder="请选择"
                               disabled>
                      <el-option v-for="item in state.vehicleType"
                                 :key="item.CarId"
                                 :label="item.carName"
                                 :value="item.carId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="16"
                        :xl="8">
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证正面"
                                prop="idCardFrontImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.idCardFrontImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.idCardFrontImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!-- <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.idCardFrontImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证反面"
                                prop="idCardBackImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.idCardBackImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.idCardBackImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!-- <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.idCardBackImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>

                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="行驶证正面"
                                prop="drivingLicenseCarImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!--  <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="行驶证反面"
                                prop="drivingLicenseFrontImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!--  <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="驾驶证"
                                prop="driverLicenseImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.driverLicenseImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.driverLicenseImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!-- <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.driverLicenseImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车辆外观"
                                prop="carImgUrl">
                    <el-image :src="state.detailsDriverInfo.authInfo.carImgUrl"
                              :preview-src-list="[state.detailsDriverInfo.authInfo.carImgUrl]"
                              class="imgto"
                              style="width: 146px; height: 146px"></el-image>
                    <!-- <Eup-Cropper @resUploadData="resUploadData" :src="state.detailsDriverInfo.authInfo.carImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </section>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 详情资料窗口 end -->
    <!-- 编辑窗口 begin -->
    <el-dialog title="编辑司机信息"
               :close-on-click-modal="false"
               v-model="state.editDriverInfoVisible"
               width="60%"
               @close="closeEditDialog">
      <el-tabs v-model="state.activeName">
        <el-tab-pane label="基础信息"
                     name="first">
          <section style="padding: 24px 48px 74px 24px">
            <el-form ref="editForm"
                     :model="state.detailsDriverInfo.baseInfo"
                     :rules="state.editBaseFormRules"
                     label-width="100px"
                     :inline="false">
              <el-row>
                <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18"> -->
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机姓名"
                                prop="realName">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.realName"
                              autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机类型"
                                prop="driverType">
                    <el-select v-model="state.detailsDriverInfo.baseInfo.driverType"
                               placeholder="请选择"
                               disabled>
                      <el-option v-for="item in state.editoptionstypede"
                                 :key="item.DriverTypede"
                                 :label="item.label"
                                 :value="item.DriverTypede"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机状态"
                                prop="driverStatus">
                    <el-select v-model="state.detailsDriverInfo.baseInfo.driverStatus"
                               placeholder="请选择">
                      <el-option v-for="item in state.options"
                                 :key="item.valuee"
                                 :label="item.label"
                                 :value="item.valuee"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="账户余额"
                                prop="balance">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.balance"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="信用度"
                                prop="credit">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.credit"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="司机评分"
                                prop="driverScore">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.driverScore"
                              autocomplete="off"
                              :disabled="true" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="商家绑定"
                                prop="bindStoreNo">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.bindStoreNo"
                              autocomplete="off"
                              disabled />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="店铺名称"
                                prop="bindStoreName">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.bindStoreName"
                              autocomplete="off"
                              disabled />
                  </el-form-item>
                </el-col>

                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="8"
                        :xl="6">
                  <el-form-item label="交易费率"
                                prop="transactionRate">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.transactionRate"
                              autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="16"
                        :lg="20"
                        :xl="20">
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="4"
                        :xl="4">
                  <el-button type="primary"
                             @click="UnboundShop()"
                             v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverinfo:UpdateSysStoreUnbound`])"
                             class="fr">解绑商城店铺</el-button>
                </el-col>
                <!-- </el-col> -->
              </el-row>
            </el-form>
          </section>
        </el-tab-pane>
        <el-tab-pane label="认证信息"
                     name="second">
          <section style="padding: 24px 48px 74px 24px">
            <el-form ref="editForms"
                     :model="state.detailsDriverInfo.authInfo"
                     :rules="state.editAuthFormRules"
                     label-width="100px"
                     :inline="false">
              <el-row>
                <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18"> -->
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="真实姓名"
                                prop="realName2">
                    <el-input v-model="state.detailsDriverInfo.baseInfo.realName"
                              autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证号"
                                prop="idCardNo">
                    <el-input v-model="state.detailsDriverInfo.authInfo.idCardNo"
                              autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车牌号"
                                prop="licensePlate">
                    <el-input v-model="state.detailsDriverInfo.authInfo.licensePlate"
                              autocomplete="off" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车辆类型"
                                prop="driverType">
                    <el-select v-model="state.CarType"
                               placeholder="请选择">
                      <el-option v-for="item in state.vehicleType"
                                 :key="item.CarId"
                                 :label="item.carName"
                                 :value="item.carId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="16"
                        :xl="8">
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证正面"
                                prop="idCardFrontImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData1"
                                 @defaultSrc="deleteImg1"
                                 :src="state.detailsDriverInfo.authInfo.idCardFrontImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="身份证反面"
                                prop="idCardBackImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData2"
                                 @defaultSrc="deleteImg2"
                                 :src="state.detailsDriverInfo.authInfo.idCardBackImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>

                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="行驶证正面"
                                prop="drivingLicenseFrontImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData3"
                                 @defaultSrc="deleteImg3"
                                 :src="state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="行驶证反面"
                                prop="drivingLicenseCarImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData4"
                                 @defaultSrc="deleteImg4"
                                 :src="state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="驾驶证"
                                prop="driverLicenseImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData5"
                                 @defaultSrc="deleteImg5"
                                 :src="state.detailsDriverInfo.authInfo.driverLicenseImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="车辆照片"
                                prop="carImgUrl">
                    <Eup-Cropper @resUploadData="resUploadData6"
                                 @defaultSrc="deleteImg6"
                                 :src="state.detailsDriverInfo.authInfo.carImgUrl"
                                 :aspectRatio="4/3"
                                 fileType="Certificate"></Eup-Cropper>
                  </el-form-item>
                </el-col>
                <!-- </el-col> -->
              </el-row>
            </el-form>
          </section>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeEditDialog">取 消</el-button>
          <el-button type="primary"
                     @click="editDialogFormSave(state)">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑窗口 end -->
  </div>
</template>
<script>
import { reactive, onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { elConfirmDialog, IsNullOrEmpty } from "@/common/js/comm";
import { driverGetPage, getBaseDriverInfo, getDriverIdentityInfo, putdrierinfo, putdrierIndes, UpdateCancelDriver, UpdateSysStoreUnbound } from "@/serviceApi/driver/driverList";
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue";
import { DeliveryCarTypeGetCarType } from "@/serviceApi/freight/vehicleConfig";
import EnumConfig from "@/enum/EnumConfig";
import EupCropper from '@/components/eup-uploadPhoto/eup-cropper.vue'
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: "DriverList",
  components: {
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs,
    EupCropper: EupCropper
  },
  setup () {
    var editForm = ref(null);
    var editForms = ref(null);
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      query: {
        driverType: "0",
        driverStatus: "0",
        realName: "",
        licensePlate: ""
      },
      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      multipleSelection: [],
      delList: [],
      pageTotal: 0,
      form: {},
      dynamicFilter: {},
      detailsVisible: false,//详情对话框显示
      //司机信息编辑/详情
      detailsDriverInfo: {
        //基本信息
        baseInfo: {
          realName: "",
          driverType: "1",
          balance: 0,
          driverScore: 0,
          credit: 0,
          driverStatus: "1",
          bindStoreNo: "",
          driverId: "",
          bindStoreName: "",
          version: 0
        },
        //认证信息
        authInfo: {
          realName2: "",
          idCardNo: "",
          licensePlate: "",
          idCardFrontImgUrl: "",
          idCardBackImgUrl: "",
          drivingLicenseCarImgUrl: "",
          drivingLicenseFrontImgUrl: "",
          driverLicenseImgUrl: "",
          carImgUrl: "",
          transactionRate: "",
          idCardFrontImg: "",
          idCardBackImg: "",
          drivingLicenseCarImg: "",
          drivingLicenseFrontImg: "",
          driverLicenseImg: "",
          carImg: "",
          driverId: "",
          carId: ""
        }
      },
      editBaseFormRules: {
        realName: [{ required: true, message: '请输入司机姓名', trigger: 'blur' }]
      },
      editAuthFormRules: {
        realName2: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }]
      },
      loading: false,
      //司机类型(查)
      optionstypede: [
        {
          DriverTypede: '0', label: '全部'
        },
        {
          DriverTypede: '1', label: '入驻'
        },
        {
          DriverTypede: '2', label: '商家自有'
        }
      ],
      editoptionstypede: [
        {
          DriverTypede: '1', label: '入驻'
        },
        {
          DriverTypede: '2', label: '商家自有'
        }
      ],
      //司机状态(查)
      optionse: [
        {
          valuee: '0', label: '全部'
        },
        {
          valuee: '1', label: '正常'
        },
        {
          valuee: '2', label: '锁定'
        }
      ],
      options: [
        {
          valuee: '1', label: '正常'
        },
        {
          valuee: '2', label: '锁定'
        }
      ],
      vehicleType: [],
      CarType: "",
      activeName: 'first',//编辑页的tab
      activeName2: 'first',//详情页的tab
      editDriverInfoVisible: false,//编辑对话框显示
      hideIDCard_f: false,
      hideIDCard_r: false,
      hideDriveLicense_r: false,
      hideDriveLicense_f: false,
      hideDriverLice: false,
      hideCarImg: false,
      limitCount: 1,
      frequency: 0,
      oldAuthInfo: {}//记录每次从后台获取到的认证数据
    });
    onMounted(() => {
      getData();
    })
    //获取表单信息
    const getData = () => {
      var params = {
        "currentPage": state.pageIndex,
        "pageSize": state.pageSize,
        "filter.driverType": state.query.driverType == '0' ? null : state.query.driverType,
        "filter.driverStatus": state.query.driverStatus == '0' ? null : state.query.driverStatus,
        "filter.realName": state.query.realName,
        "filter.licensePlate": state.query.licensePlate,
        "dynamicFilter": state.dynamicFilter
      }
      state.loading = true;
      driverGetPage(params).then(function (res) {
        if (res.code == 1) {
          state.pageTotal = res.data.total;//初始化列表数据总数
          state.tableData = res.data.list;
          //添加num序号字段
          state.tableData.forEach((data, i) => {
            data.num = i + 1;
          });
        } else {
          ElMessage.error(res.msg);
        }
        state.loading = false;
      });
    }
    // 多选操作
    const handleSelectionChange = (val) => {
      state.multipleSelection = val;
    }
    // 触发搜索按钮
    const handleSearch = () => {
      state.pageIndex = 1
      getData();
    }

    const hasBackFormat = ((row) => {
      if (row.driverType == state.editoptionstypede[0].DriverTypede) {
        return "入驻";
      } else if (row.driverType == state.editoptionstypede[1].DriverTypede) {
        return "商家自有";
      }
    });
    //解绑商城店铺
    const UnboundShop = () => {
      let pamser = {
        "BindStoreNo": state.detailsDriverInfo.baseInfo.bindStoreNo,
        "DriverId": state.detailsDriverInfo.authInfo.driverId
      }
      UpdateSysStoreUnbound(pamser).then(function (res) {
        if (res.code == 1) {
          ElMessage.success("解绑成功");
          getData()
        }
        else {
          ElMessage.error(res.msg);
          getData()
        }
      });
    }
    //详情
    const handleClickDetails = ((row) => {
      state.detailsVisible = true;
      //获取基本信息
      getBaseDriverInfo(row.driverId).then(res => {
        if (res.code == 1) {
          state.detailsDriverInfo.baseInfo.realName = res.data.realName;
          state.detailsDriverInfo.baseInfo.driverType = state.editoptionstypede[0].DriverTypede == res.data.driverType ? state.editoptionstypede[0].label : state.editoptionstypede[1].label;
          state.detailsDriverInfo.baseInfo.balance = res.data.balance;
          state.detailsDriverInfo.baseInfo.transactionRate = res.data.transactionRate;
          state.detailsDriverInfo.baseInfo.driverScore = res.data.driverScore;
          state.detailsDriverInfo.baseInfo.credit = res.data.credit;
          state.detailsDriverInfo.baseInfo.bindStoreNo = res.data.bindStoreNo;
          state.detailsDriverInfo.baseInfo.bindStoreName = res.data.bindStoreName;
          state.detailsDriverInfo.baseInfo.driverStatus = state.options[0].valuee == res.data.driverStatus ? state.options[0].label : state.options[1].label;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //获取可用车型
      DeliveryCarTypeGetCarType().then((res) => {
        if (res.code == 1) {
          state.vehicleType = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //获取认证信息
      getDriverIdentityInfo(row.driverId).then(res => {
        if (res.code == 1) {
          state.detailsDriverInfo.authInfo.realName2 = res.data.realName;
          state.detailsDriverInfo.authInfo.idCardNo = res.data.idCardNo;
          state.detailsDriverInfo.authInfo.licensePlate = res.data.licensePlate;
          state.detailsDriverInfo.authInfo.idCardFrontImgUrl = res.data.idCardFrontImgUrl;
          state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = res.data.drivingLicenseCarImgUrl;
          state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = res.data.drivingLicenseFrontImgUrl;
          state.detailsDriverInfo.authInfo.idCardBackImgUrl = res.data.idCardBackImgUrl;
          state.detailsDriverInfo.authInfo.carImgUrl = res.data.drivingLicenseFrontImgUrl;
          state.detailsDriverInfo.authInfo.driverLicenseImgUrl = res.data.driverLicenseImgUrl;
          state.CarType = res.data.carId;
        } else {
          ElMessage.error(res.msg);
        }
      });
    });

    //编辑
    const handleClickEdit = ((index, row) => {
      state.editDriverInfoVisible = true;
      //获取基本信息
      getBaseDriverInfo(row.driverId).then(res => {
        if (res.code == 1) {
          state.detailsDriverInfo.baseInfo.realName = res.data.realName;
          state.detailsDriverInfo.baseInfo.driverType = res.data.driverType + '';
          state.detailsDriverInfo.baseInfo.balance = res.data.balance;
          state.detailsDriverInfo.baseInfo.driverScore = res.data.driverScore;
          state.detailsDriverInfo.baseInfo.credit = res.data.credit;
          state.detailsDriverInfo.baseInfo.driverStatus = res.data.driverStatus + '';
          state.detailsDriverInfo.baseInfo.driverId = row.driverId;
          state.detailsDriverInfo.baseInfo.transactionRate = row.transactionRate;
          state.detailsDriverInfo.baseInfo.bindStoreNo = row.bindStoreNo;
          state.detailsDriverInfo.baseInfo.bindStoreName = res.data.bindStoreName;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //获取可用车型
      DeliveryCarTypeGetCarType().then((res) => {
        if (res.code == 1) {
          state.vehicleType = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
      //获取认证信息
      getDriverIdentityInfo(row.driverId).then(res => {
        if (res.code == 1) {
          // state.detailsDriverInfo.authInfo.realName = res.data.realName;
          state.detailsDriverInfo.authInfo.idCardNo = res.data.idCardNo;
          state.detailsDriverInfo.authInfo.licensePlate = res.data.licensePlate;
          state.detailsDriverInfo.authInfo.idCardFrontImgUrl = res.data.idCardFrontImgUrl;
          state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = res.data.drivingLicenseCarImgUrl;
          state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = res.data.drivingLicenseFrontImgUrl;
          state.detailsDriverInfo.authInfo.idCardBackImgUrl = res.data.idCardBackImgUrl;
          state.detailsDriverInfo.authInfo.carImgUrl = res.data.drivingLicenseFrontImgUrl;
          state.detailsDriverInfo.authInfo.driverLicenseImgUrl = res.data.driverLicenseImgUrl;
          state.CarType = res.data.carId;

          state.detailsDriverInfo.authInfo.carImg = res.data.carImg;
          state.detailsDriverInfo.authInfo.idCardFrontImg = res.data.idCardFrontImg;
          state.detailsDriverInfo.authInfo.idCardBackImg = res.data.idCardBackImg;
          state.detailsDriverInfo.authInfo.drivingLicenseCarImg = res.data.drivingLicenseCarImg;
          state.detailsDriverInfo.authInfo.drivingLicenseFrontImg = res.data.drivingLicenseFrontImg;
          state.detailsDriverInfo.authInfo.driverLicenseImg = res.data.driverLicenseImg;
          state.detailsDriverInfo.authInfo.driverId = row.driverId;
          state.detailsDriverInfo.authInfo.carId = res.data.carId;
          state.oldAuthInfo = res.data;
          //身份证正面 begin
          if (res.data.idCardFrontImgUrl == undefined || res.data.idCardFrontImgUrl == null || res.data.idCardFrontImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.idCardFrontImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.idCardFrontImgUrl = res.data.idCardFrontImgUrl;
          }
          //身份证正面 end
          //身份证反面 begin
          if (res.data.idCardBackImgUrl == undefined || res.data.idCardBackImgUrl == null || res.data.idCardBackImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.idCardBackImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.idCardBackImgUrl = res.data.idCardBackImgUrl;
          }
          //身份证反面 end
          //行驶证正面 begin
          if (res.data.drivingLicenseFrontImgUrl == undefined || res.data.drivingLicenseFrontImgUrl == null || res.data.drivingLicenseFrontImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = res.data.drivingLicenseFrontImgUrl;
          }
          //行驶证正面 end
          //行驶证反面 begin
          if (res.data.drivingLicenseCarImgUrl == undefined || res.data.drivingLicenseCarImgUrl == null || res.data.drivingLicenseCarImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = res.data.drivingLicenseCarImgUrl;
          }
          //行驶证反面 end
          //驾驶证 begin         
          if (res.data.driverLicenseImgUrl == undefined || res.data.driverLicenseImgUrl == null || res.data.driverLicenseImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.driverLicenseImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.driverLicenseImgUrl = res.data.driverLicenseImgUrl;
          }
          //驾驶证 end
          //车辆照片 begin
          if (res.data.carImgUrl == undefined || res.data.carImgUrl == null || res.data.carImgUrl == "") {
            //同一条记录，图片删除后恢复旧的图片地址，而不是空的，因为该记录未上传到后台
            state.detailsDriverInfo.authInfo.carImgUrl = "";
          } else {
            state.detailsDriverInfo.authInfo.carImgUrl = res.data.carImgUrl;
          }
          //车辆照片 end
        } else {
          ElMessage.error(res.msg);
        }
      });
    });

    //编辑 begin
    //编辑确定按钮
    const editDialogFormSave = () => {
      saveBaseDriverInfo();
      saveAuthDriverInfo();
    }
    //保存基本的司机信息
    const saveBaseDriverInfo = () => {
      if (IsNullOrEmpty(state.detailsDriverInfo.baseInfo.realName) || IsNullOrEmpty(state.detailsDriverInfo.baseInfo.transactionRate)) {
        ElMessage.error("司机姓名不能为空");
        return;
      }
      if (state.detailsDriverInfo.baseInfo.transactionRate > 1) {
        return ElMessage.error("交易费率不能大于1");
      }
      var params = {
        // "driverType": state.detailsDriverInfo.baseInfo.driverType,
        // "bindStoreNo": state.detailsDriverInfo.baseInfo.bindStoreNo,
        "realName": state.detailsDriverInfo.baseInfo.realName,
        "driverStatus": state.detailsDriverInfo.baseInfo.driverStatus,
        "driverId": state.detailsDriverInfo.baseInfo.driverId,
        "transactionRate": state.detailsDriverInfo.baseInfo.transactionRate
      }
      putdrierinfo(params).then(res => {
        if (res.code == 1) {
          ElMessage.success('基本信息更新成功');
          state.editDriverInfoVisible = false;
          editForm.value.resetFields();
          editForms.value.resetFields();
          getData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    }
    //保存司机认证信息
    const saveAuthDriverInfo = () => {
      if (IsNullOrEmpty(state.detailsDriverInfo.baseInfo.realName)) {
        ElMessage.error("真实姓名不能为空");
        return;
      }
      var params = {
        "driverId": state.detailsDriverInfo.authInfo.driverId,
        "carId": state.CarType,
        "realName": state.detailsDriverInfo.baseInfo.realName,
        "idCardNo": state.detailsDriverInfo.authInfo.idCardNo,
        "idCardFrontImg": state.detailsDriverInfo.authInfo.idCardFrontImg,
        "idCardBackImg": state.detailsDriverInfo.authInfo.idCardBackImg,
        "driverLicenseImg": state.detailsDriverInfo.authInfo.driverLicenseImg,
        "licensePlate": state.detailsDriverInfo.authInfo.licensePlate,
        "drivingLicenseFrontImg": state.detailsDriverInfo.authInfo.drivingLicenseFrontImg,
        "drivingLicenseCarImg": state.detailsDriverInfo.authInfo.drivingLicenseCarImg,
        "carImg": state.detailsDriverInfo.authInfo.carImg
      }
      putdrierIndes(params).then(res => {
        if (res.code == 1) {
          ElMessage.success('认证信息更新成功');
          state.editDriverInfoVisible = false;
          editForm.value.resetFields();
          editForms.value.resetFields();
          getData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    }
    //编辑 end

    //关闭编辑对话框
    const closeEditDialog = () => {
      state.editDriverInfoVisible = false;
      editForm.value.resetFields();
      editForms.value.resetFields();
    }

    //切换司机类型
    const driverTypeHandle = () => {
      getData();
    }
    //切换司机状态
    const driverStatusHandle = () => {
      getData();
    }

    /**
     * @description 子组件返回分页数据
     * @author weig
     * @param {Object} obj
     */
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    }
    const handleClickDelete = (row) => {
      elConfirmDialog(ElMessageBox, '此操作将永久删除该数据, 是否继续?', '提示', '', () => {
        UpdateCancelDriver({
          driverId: row.driverId
        }).then(res => {
          if (res.code == 1) {
            ElMessage.success("注销成功");
            getData();
          } else {
            ElMessage.error("注销失败！");
          }
        });
      }, () => {
        ElMessage.info("取消注销！");
      });
    };
    /**
     * @description 上传文件后返回来的数据
     * @author weig
     * @param {Object} res 服务端成功返回数据
     */
    const resUploadData1 = (res) => {
      state.detailsDriverInfo.authInfo.idCardFrontImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.idCardFrontImgUrl = res.data.url;
    }
    /**
 * @description 上传文件后返回来的数据
 * @author weig
 * @param {Object} res 服务端成功返回数据
 */
    const resUploadData2 = (res) => {
      state.detailsDriverInfo.authInfo.idCardBackImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.idCardBackImgUrl = res.data.url;
    }
    /**
 * @description 上传文件后返回来的数据
 * @author weig
 * @param {Object} res 服务端成功返回数据
 */
    const resUploadData3 = (res) => {
      state.detailsDriverInfo.authInfo.drivingLicenseFrontImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = res.data.url;
    }
    /**
 * @description 上传文件后返回来的数据
 * @author weig
 * @param {Object} res 服务端成功返回数据
 */
    const resUploadData4 = (res) => {
      state.detailsDriverInfo.authInfo.drivingLicenseCarImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = res.data.url;
    }
    /**
 * @description 上传文件后返回来的数据
 * @author weig
 * @param {Object} res 服务端成功返回数据
 */
    const resUploadData5 = (res) => {
      state.detailsDriverInfo.authInfo.driverLicenseImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.driverLicenseImgUrl = res.data.url;
    }
    /**
     * @description 上传文件后返回来的数据
     * @author weig
     * @param {Object} res 服务端成功返回数据
     */
    const resUploadData6 = (res) => {
      state.detailsDriverInfo.authInfo.carImg = res.data.eurl;
      state.detailsDriverInfo.authInfo.carImgUrl = res.data.url;
    }
    /**
     * @description 删除图片
     * @author weig
     * @param {String} data 地址
     */
    const deleteImg1 = (data) => {
      state.detailsDriverInfo.authInfo.idCardFrontImg = data;
      state.detailsDriverInfo.authInfo.idCardFrontImgUrl = data;
    }
    /**
 * @description 删除图片
 * @author weig
 * @param {String} data 地址
 */
    const deleteImg2 = (data) => {
      state.detailsDriverInfo.authInfo.idCardBackImg = data;
      state.detailsDriverInfo.authInfo.idCardBackImgUrl = data;
    }
    /**
 * @description 删除图片
 * @author weig
 * @param {String} data 地址
 */
    const deleteImg3 = (data) => {
      state.detailsDriverInfo.authInfo.drivingLicenseFrontImg = data;
      state.detailsDriverInfo.authInfo.drivingLicenseFrontImgUrl = data;
    }
    /**
 * @description 删除图片
 * @author weig
 * @param {String} data 地址
 */
    const deleteImg4 = (data) => {
      state.detailsDriverInfo.authInfo.drivingLicenseCarImg = data;
      state.detailsDriverInfo.authInfo.drivingLicenseCarImgUrl = data;
    }
    /**
 * @description 删除图片
 * @author weig
 * @param {String} data 地址
 */
    const deleteImg5 = (data) => {
      state.detailsDriverInfo.authInfo.driverLicenseImg = data;
      state.detailsDriverInfo.authInfo.driverLicenseImgUrl = data;
    }
    /**
 * @description 删除图片
 * @author weig
 * @param {String} data 地址
 */
    const deleteImg6 = (data) => {
      state.detailsDriverInfo.authInfo.carImg = data;
      state.detailsDriverInfo.authInfo.carImgUrl = data;
    }

    return {
      state,
      UnboundShop,
      handleSelectionChange,
      handleSearch,
      hasBackFormat,
      handleClickDelete,
      handleClickDetails,
      handleClickEdit,
      editDialogFormSave,
      closeEditDialog,
      editForm,
      editForms,
      driverTypeHandle,
      driverStatusHandle,
      getData,
      resPageData,
      resUploadData1,
      resUploadData2,
      resUploadData3,
      resUploadData4,
      resUploadData5,
      resUploadData6,
      deleteImg1,
      deleteImg2,
      deleteImg3,
      deleteImg4,
      deleteImg5,
      deleteImg6,
    }
  }
};
</script>
<style>
</style>
