<template >
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="User"
                firstCrumbs="用户信息"
                secondCrumbs="用户配置" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <div class="container">
      <div class="handle-box userconfig">
        <template v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:getpage`,`api${VIEW_VERSION}:user:userconfig:add`,`api${VIEW_VERSION}:user:userconfig:batchdelete`])">

          <el-form ref="state.query"
                   :model="query"
                   :inline="true"
                   v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:getpage`])">
            <el-form-item label="用户名/手机">
              <el-select v-model="userId"
                         filterable
                         :remote-method="remoteHandle"
                         remote
                         placeholder="请输入"
                         :clearable="true"
                         @clear="onClearDialog">
                <el-option v-for="item in optionUesr"
                           :key="item.userId"
                           :label="item.nickName"
                           :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="参数名称">
              <el-input v-model="paramName"
                        placeholder="参数名称">
              </el-input>
            </el-form-item>
            <el-form-item label="参数Key">
              <el-input v-model="paramKey"
                        placeholder="参数Key">

              </el-input>
            </el-form-item>
            <el-form-item label="配置状态">
              <el-select v-model="approvalStatus"
                         placeholder="配置状态"
                         class="handle-select  ">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-right:10px">
              <el-button type="primary"
                         icon="search"
                         @click="handleSearch">搜索
              </el-button>
            </el-form-item>
            <el-form-item style="margin-right:10px">
              <el-button type="primary"
                         icon="Plus"
                         @click="handleClickAddData"
                         v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:add`])">
                新增
              </el-button>
            </el-form-item>
            <el-form-item style="margin-right:10px">
              <el-button type="danger"
                         icon="Delete"
                         class="handle-del mr10"
                         @click="handleClickBatchDelete()"
                         v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:batchdelete`])">
                批量删除
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <el-table :data="tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="loading">
        <el-table-column type="selection"
                         width="60"
                         align="center"></el-table-column>
        <el-table-column prop="num"
                         label="序号"
                         width="60"
                         align="center"></el-table-column>
        <el-table-column prop="paramKey"
                         label="参数Key"
                         min-width="90"
                         align="center"></el-table-column>
        <el-table-column prop="userName"
                         label="用户名"
                         min-width="90"
                         align="center"></el-table-column>
        <el-table-column prop="paramName"
                         label="参数名称"
                         min-width="100"
                         align="center"></el-table-column>
        <el-table-column prop="paramValue"
                         label="参数值"
                         min-width="60"
                         align="center"></el-table-column>
        <el-table-column prop="isActive"
                         label="配置状态"
                         min-width="60"
                         align="center"
                         :formatter="hasValidFormaStatus"></el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         min-width="120"
                         align="center"></el-table-column>
        <el-table-column label="操作"
                         min-width="160"
                         align="center"
                         fixed="right"
                         v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:update`,`api${VIEW_VERSION}:user:userconfig:get`,`api${VIEW_VERSION}:user:userconfig:delete`])">
          <template #default="scope">
            <el-button type="text"
                       icon="Edit"
                       @click="handleEdit(scope.row)"
                       v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:update`])">编辑</el-button>
            <el-button type="text"
                       icon="Check"
                       @click="handleClickDetails(scope.row)"
                       v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:get`])">查看详情</el-button>
            <el-button type="text"
                       icon="Delete"
                       class="red"
                       @click="handleClickDelete(scope.row)"
                       v-if="checkPermission([`api${VIEW_VERSION}:user:userconfig:delete`])">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination :current-page="pageIndex"
                     :pagesizes="[10, 20, 50, 100]"
                     :pagesize="pageSize"
                     :total="pageTotal"
                     @getPageData="configList"
                     @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 添加/编辑窗口 begin -->
    <el-dialog :title="dialogTitle"
               v-model="addDialogFormVisible"
               width="60%"
               :close-on-click-modal="false"
               @close="closeDialog">
      <el-form ref="refAddForm"
               :model="addForm"
               label-width="100px"
               :inline="false">
        <el-row>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item :label="userNameType"
                          prop="userName">
              <el-select v-model="addForm.userId"
                         filterable
                         placeholder="请输入"
                         :remote-method="remoteHandle"
                         remote
                         :clearable="true"
                         @clear="onClearDialog"
                         :disabled="userRoleCondition">
                <el-option v-for="item in optionUesr"
                           :key="item.userId"
                           :label="item.nickName"
                           :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数名称"
                          prop="paramName">
              <el-input v-model="addForm.paramName"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数值"
                          prop="paramValue">
              <el-input v-model="addForm.paramValue"
                        autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数Key"
                          prop="paramKey">
              <el-input v-model="addForm.paramKey"
                        autocomplete="off"
                        :disabled="Eidt" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数状态"
                          prop="isActive">
              <el-select v-model="addForm.isActive"
                         placeholder="请选择">
                <el-option v-for="item in paramType"
                           :key="item.isActive"
                           :label="item.label"
                           :value="item.isActive"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        autocomplete="off" />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog()">取 消</el-button>
          <el-button type="primary"
                     @click="addDialogFormSave()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 end -->
    <el-dialog title="详情"
               v-model="detailsVisible"
               width="60%"
               :close-on-click-modal="false">
      <el-form ref="form"
               :model="particulars"
               label-width="80px"
               :inline="false">
        <el-row>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="用户名"
                          prop="userName">
              <el-input v-model="particulars.userName"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数名称"
                          prop="paramName">
              <el-input v-model="particulars.paramName"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数值"
                          prop="paramValue">
              <el-input v-model="particulars.paramValue"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数状态"
                          prop="isActive">
              <el-input v-model="particulars.isActive"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="参数Key"
                          prop="paramKey">
              <el-input v-model="particulars.paramKey"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="6">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="particulars.remark"
                        autocomplete="off"
                        :disabled="true" />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import EnumConfig from "@/enum/EnumConfig";
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue";
import { elConfirmDialog, IsNullOrEmpty } from "@/common/js/comm";
import { getUserInfo } from '@/serviceApi/permission/userRole'
import {
  GetPageUserConfigList,
  SoftDeleteUserConfig,
  AddUserConfig,
  UpdateUserConfig,
  BatchSoftDeleteUserConfig,
} from "@/serviceApi/user/userConfig";
import { ElMessage, ElMessageBox } from "element-plus";
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: "",
  components: {
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs
  },
  setup () {
    const tableData = ref([]);
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION, paramName: "", paramValue: "", pageIndex: 1, pageSize: 10, pageTotal: 0, addDialogFormVisible: false,
      dialogTitle: "", addForm: { isActive: '1', paramKey: "", paramName: "", paramValue: "", remark: "", userId: "" }, userRoleCondition: false, optionUesr: [],
      approvalStatus: "0", Eidt: false, detailsVisible: false, particulars: {}, userId: "", paramKey: "",
      userNameType: "手机号/邮箱",
      options: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '有效'
      }, {
        value: '2',
        label: '无效'
      }],
      paramType: [{ isActive: '1', label: '有效' }, { isActive: '2', label: '无效' }],
      form: {
        userId: "",
      },
      query: {

      },
      multipleSelection: [],
      loading: false,
    });
    onMounted(() => {
      configList();
    })
    const configList = () => {
      var parems = {
        currentPage: state.pageIndex,
        pageSize: state.pageSize,
        "filter.paramName": state.paramName,
        "filter.userId": state.userId,
        "filter.paramKey": state.paramKey,
        "filter.isActive": state.approvalStatus == '0' ? null : state.approvalStatus,
      };
      GetPageUserConfigList(parems).then((res) => {
        if (res.code == 1) {
          state.pageTotal = res.data.total; //初始化列表数据总数
          tableData.value = res.data.list;
          tableData.value.forEach((data, i) => {
            data.num = i + 1;
          });
        } else {
          ElMessage.error(res.msg);
        }
      })
    };
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    };
    const hasValidFormaStatus = (row) => {
      if (row.isActive == 1) {
        return " 有效";
      } else if (row.isActive == 2) {
        return " 无效";
      } else {
        return "全部";
      }
    };
    const handleSearch = () => {
      state.pageIndex = 1;
      configList();
    };
    const handleClickBatchDelete = () => {
      if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          var pamser = {
            "userId": state.userId,
            "configId": state.multipleSelection
          }
          BatchSoftDeleteUserConfig(pamser)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                configList();
              } else {
                ElMessage.error("删除失败！");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除！");
        }
      );
    };
    const handleClickDelete = (row) => {
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          var params = {
            params: {
              "configId": row.configId,
              "userId": row.userId
            }
          }
          SoftDeleteUserConfig(params).then((res) => {
            if (res.code == 1) {
              ElMessage.success("删除成功");
              configList();
            } else {
              ElMessage.error("删除失败！");
            }
          })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除！");
        }
      );
    };
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.configId;
      });
    };
    const handleClickAddData = () => {
      state.addDialogFormVisible = true;
      state.dialogTitle = "新增用户配置";
      state.addForm.add = "add";
      state.Eidt = false;
      state.userRoleCondition = false;
    };
    const closeDialog = () => {
      state.addDialogFormVisible = false;
      state.addForm = { isActive: '1', paramKey: "", paramName: "", paramValue: "", remark: "", userId: "" };
    }
    const addDialogFormSave = () => {
      var params = {};
      if (state.dialogTitle == "新增用户配置" && state.addForm.add == "add") {
        params = {
          "paramKey": state.addForm.paramKey,
          "userId": state.addForm.userId,
          "paramName": state.addForm.paramName,
          "paramValue": state.addForm.paramValue,
          "isActive": state.addForm.isActive,
          "remark": state.addForm.remark
        }
        if (params.paramKey == "") {
          return ElMessage.info("参数Key不能为空!");
        }
        if (params.userId == "") {
          return ElMessage.info("手机号或者邮箱不能为空!");
        }
        if (params.paramName == "") {
          return ElMessage.info("参数名称不能为空!");
        }
        if (params.paramValue == "") {
          return ElMessage.info("参数值不能为空!");
        }
        AddUserConfig(params).then((res) => {
          if (res.code == 1) {
            ElMessage.success("添加成功");
            configList();
            state.addDialogFormVisible = false;
            state.addForm = { isActive: '1', paramKey: "", paramName: "", paramValue: "", remark: "", userId: "" };
          } else {
            ElMessage.info(res.msg);
          }
        })
      } else {
        params = {
          "configId": state.addForm.configId,
          "paramKey": state.addForm.paramKey,
          "userId": state.addForm.userIdNew,
          "paramName": state.addForm.paramName,
          "paramValue": state.addForm.paramValue,
          "isActive": state.addForm.isActive,
          "remark": state.addForm.remark
        }
        if (params.paramKey == "") {
          return ElMessage.info("参数Key不能为空!");
        }
        if (params.userId == "") {
          return ElMessage.info("手机号或者邮箱不能为空!");
        }
        if (params.paramName == "") {
          return ElMessage.info("参数名称不能为空!");
        }
        if (params.paramValue == "") {
          return ElMessage.info("参数值不能为空!");
        }
        UpdateUserConfig(params).then((res) => {
          if (res.code == 1) {
            ElMessage.success("编辑成功");
            configList();
            state.addDialogFormVisible = false;
          }
          else {
            ElMessage.info(res.msg);
          }
        })
      }
    };
    const onClearDialog = () => {
      state.optionUesr.length = 0;
    }
    const remoteHandle = ((value) => {
      if (IsNullOrEmpty(value)) {
        return;
      }
      let params = {
        name: value
      }
      if (params.name.trim() == "") {
        state.optionUesr = [];
        return;
      }
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
        state.timeoutId = null;
      }
      state.timeoutId = setTimeout(() => {
        getUserInfo(params.name).then(function (res) {
          if (res.code == 1) {
            state.optionUesr = [];
            if (res.data.user.length > 0) {
              res.data.user.forEach((item) => {
                state.optionUesr.push({
                  userId: item.userId,
                  nickName: item.nickName,
                  phone: item.phone,
                  email: item.email,
                  userName: item.userName
                });
              });
            }
          } else {
            ElMessage.error(res.msg);
          }
        });
      }, 500);
    });
    const handleEdit = (row) => {
      state.addDialogFormVisible = true;
      var strJson = JSON.stringify(row);
      var jsonRow = JSON.parse(strJson)
      state.dialogTitle = "编辑用户配置";
      state.addForm.eit = "eit";
      state.addForm = {
        "configId": jsonRow.configId,
        "paramKey": jsonRow.paramKey,
        "userId": jsonRow.userName,
        "paramName": jsonRow.paramName,
        "paramValue": jsonRow.paramValue,
        "isActive": jsonRow.isActive + '',
        "remark": jsonRow.remark,
        "userIdNew": jsonRow.userId,
      }
      state.userNameType = "用户名";
      state.Eidt = true;
      state.userRoleCondition = true;
    };
    const handleClickDetails = (row) => {
      state.detailsVisible = true;
      var strJson = JSON.stringify(row);
      state.particulars = JSON.parse(strJson)
      state.particulars.isActive = hasValidFormaStatus(state.particulars)
    };
    return {
      ...toRefs(state), resPageData, configList, hasValidFormaStatus, handleSearch, handleClickBatchDelete, handleSelectionChange,
      handleClickDelete, closeDialog, addDialogFormSave, handleClickAddData, onClearDialog, remoteHandle, handleEdit, handleClickDetails, tableData
    }
  },
}
</script>
<style>
</style>