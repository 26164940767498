/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-10-19 09:39:03
 * @LastEditors: weig
 * @LastEditTime: 2022-02-10 16:57:14
 */

export const menu = [
    {
        // icon: "el-icon-s-home",
        icon: "HomeFilled",
        index: `/SysHome`,
        title: "系统首页",
    },
    {
        icon: "User",
        index: "2-2",
        title: "用户管理",
        children:[
            {
                index: `/User/UserList`,
                title: "用户列表",
            },
            {
                index: `/User/Address`,
                title: "用户地址",
            },
            {
                index: `/User/UserConfig`,
                title: "用户配置",
            },
            {
                index: `/User/LoginLog`,
                title: "登陆日志",
            },
            {
                index: `/User/OperLog`,
                title: "操作日志",
            }
        ]
    },
    {
        icon: "Ticket",
        index: "2-3",
        title: "订单管理",
        children:[
            {
                index: `/Order/OrderList`,
                title: "订单列表",
            }
        ]
    },
    {
        icon: "Van",
        index: "2-4",
        title: "司机管理",
        children:[
            {
                index: `/Driver/DriverList`,
                title: "司机列表",
            },
            {
                index: `/Driver/Apply`,
                title: "司机申请",
            },{
                index: `/Driver/ApplyCancel`,
                title: "司机解绑",
            }
        ]
    },
    {
        icon: "ScaleToOriginal",
        index: "2-5",
        title: "运费管理",
        children:[
            {
                index: `/Freight/PriceConfig`,
                title: "价格配置",
            },
            {
                index: `/Freight/VehicleConfig`,
                title: "车型配置",
            }
        ]
    },
    {
        icon: "Expand",
        index: "2-6",
        title: "系统管理",
        children:[
            {
                index: `/System/AreaManage`,
                title: "地区管理",
            },
            {
                index: `/System/Tenant`,
                title: "租户管理",
            },
            {
                index: `/System/Cache`,
                title: "缓存管理",
            },
            {
                index: `/System/Feedback`,
                title: "建议反馈管理",
            }
        ]
    },
    {
        icon: "Sell",
        index: "2-7",
        title: "支付管理",
        children:[
            {
                index: `/Pay/PayRecord`,
                title: "支付记录",
            },
            {
                index: `/Pay/RefundRecord`,
                title: "退款记录",
            },
            {
                index: `/Pay/TransferRecord`,
                title: "转账记录",
            },
        ]
    },
    {
        icon: "Money",
        index: "2-8",
        title: "资金管理",
        children:[
            {
                index: `/Fund/SysBalance`,
                title: "系统余额",
            },
                {
                index: `/Fund/Frozen`,
                title: "系统冻结",
            },
            {
                index: `/Fund/BalanceRecord`,
                title: "余额记录",
            },
            {
                index: `/Fund/RechargeRecord`,
                title: "充值记录",
            },
            {
                index: `/Fund/WithdrawRecord`,
                title: "提现记录",
            },
        ]
    },
    {   
        icon: "Tools",
        index: "2-9",
        title: "权限管理",
        children:[
            {
                index: `/Permission/Admin/UserRole`,
                title: "用户角色",
            },
            {
                index: `/Permission/Admin/Role`,
                title: "角色管理",
            },
            {
                index: `/Permission/Admin/Interface`,
                title: "接口管理",
            },
            {
                index: `/Permission/Admin/ViewManage`,
                title: "视图管理",
            },
            {
                index: `/Permission/Admin/PermissionManage`,
                title: "权限管理",
            },
            // {
            //     index: `/Permission/Admin/Role-Permission`,
            //     title: "角色权限",
            // },
        ]
    },
    {   
    icon: "CreditCard",
        index: "2-10",
        title: "资源管理",
        children:[
            {
                index: `/Image/Image`,
                title: "图片中心",
            },
        ]
    },
    {   
    icon: "Flag",
        index: "2-11",
        title: "营销管理",
        children:[
            {
                index: `/Sales/RedPack`,
                title: "红包配置",
            },
            {
            index: `/Sales/Coupon`,
                title: "优惠券配置",   
            },
            {
                index: `/Sales/SalesBanner`,
                title: "横幅配置",
            }
        ]
    },
    {   
    icon: "Notebook",
        index: "2-12",
        title: "内容管理",
        children:[
            {
                index: `/Article/ArticleType`,
                title: "内容分类",
            },
            {
                index: `/Article/ArticleInfo`,
                title: "内容详情",
            }
        ]
    },
    {   
        icon: "Comment",
        index: "2-13",
        title: "消息管理",
        children:[
            {
                index: `/Chat/ChatSysMsg`,
                title: "消息管理",
            }
        ]
    }
    // {   
    //   icon: "el-icon-star-off",
    //     index: `/Demo`,
    //     title: "测试Demo",
    // }
];
