/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-07-06 10:54:06
 * @LastEditors: weig
 * @LastEditTime: 2021-07-07 10:04:54
 */

import permission from './permission.js'
export default permission
