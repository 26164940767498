/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:14:42
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取司机列表信息
export const driverGetPage = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Driver/DriverInfo/GetPage`, params);
}
/* 司机基础信息详情 */
export const getBaseDriverInfo = (params) => { 
    return request.apiGet(`/api${API_VERSION}/Driver/DriverInfo/Get?id=${params}`)
}
/* 司机认证信息详情 */
export const getDriverIdentityInfo = (params) => { /* 司机认证信息详情 */
    return request.apiGet(`/api${API_VERSION}/Driver/DriverIdentityInfo/Get?driverId=` + params)
}
/* 司机基础信息修改 */
export const putdrierinfo = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Driver/DriverInfo/Update`, params)
}
/* 司机认证信息修改 */
export const putdrierIndes = (params) => { 
    return request.apiPut(`/api${API_VERSION}/Driver/DriverIdentityInfo/Update`, params)
}
/* 司机注销 */
export const UpdateCancelDriver=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Driver/DriverInfo/UpdateSysCancelDriver`,params)
}
/* 后台解绑商城店铺 */
export const UpdateSysStoreUnbound = (params)=>{
    return request.apiPost(`/api${API_VERSION}/Driver/DriverInfo/UpdateSysStoreUnbound`, params);
}