<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-10-09 13:40:15
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:13:50
-->
<template>
    <div class="crumbs">
        <el-breadcrumb separator="/" style="line-height:normal;">
            <el-breadcrumb-item>
                <!-- <i :class="icon"></i>  -->
                  <el-icon>
                    <component :is="icon"/>
                </el-icon>
                {{firstCrumbs}}
            </el-breadcrumb-item>
            <el-breadcrumb-item>
              {{secondCrumbs}}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
export default {
  name: 'EupCrumbs',
  props:{
    firstCrumbs:{
        type: String,
        default: ""
    },
    secondCrumbs:{
        type: String,
        default: ""
    },
    icon:{
        type: String,
        default:""
    }
  },
  setup(props, context) {
    const data = reactive({});
    onBeforeMount(() => {
    });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
    }
  },
}

</script>
<style scoped lang='less'>
</style>