import { getUrlParam, IsNullOrEmpty,editUrlQuery,delUrlParam } from '@/common/js/comm'
import request from '../utils/request'
import { setCookie ,delCookie} from '@/common/js/Cookie.js'
import { ElMessage } from 'element-plus'
import {UserInformation} from '../utils/cacheProcessing'
import EnumConfig from '../enum/EnumConfig'
var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;
export async function UaLogin ( callback) {
    request.apiPostNoToken(`/api${API_VERSION}/User/Account/Login`, { "userName": "null", "password": "null", "verifyCode": "null", "verifyCodeKey": "null", "loginToken": getUrlParam("logintoken"),"tenantId":getUrlParam("tenantId") }).then(res => {
        if (res.code == 1) {
            setCookie("token", res.data.token);
            //缓存为空时设置用户信息缓存
            //置换token时,重新获取用户信息
            UserInformation(function (result) {
            })
            callback(true)
            history.replaceState({}, '', delUrlParam(window.location.href, ["logintoken"]));
            history.replaceState({}, '', delUrlParam(window.location.href, ["tenantId"]));
            location.reload(); 
        } else {
            setCookie("token", "");
            sessionStorage.setItem("userInfo", "");
            delCookie("ASP.NET_SessionId");
            delCookie("SessionId");
            let tryCount = getUrlParam(window.location.href);
            if (!IsNullOrEmpty(tryCount)) {
                tryCount = parseInt(tryCount +1);
            } else {
                tryCount = 1;
            }
            if (tryCount > 3) {
                return ElMessage.error("登陆失败");
            } else {
                window.location.href = "" + process.env.VUE_APP_LOGIN_URL + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
            }
        }
    });
  }