<template>
    <div>
         <!-- 面包屑 begin -->
        <eup-crumbs icon="Van" firstCrumbs="司机管理" secondCrumbs="司机解绑" />
        <!-- 面包屑 end -->
       <!-- 内容区域 begin -->
        <div class="container">
            <div class="handle-box">
              <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverapplycancel:getpage`])">
                <el-form-item label="审核状态">
                  <el-select v-model="state.query.approvalStatus" placeholder="审核状态" class="handle-select mr10" @change="approvalStatusHandle">
                  <el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="真实姓名">
                  <el-input v-model="state.query.realName" placeholder="真实姓名" ></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                  <el-input v-model="state.query.idCardNo" placeholder="身份证号" ></el-input>
                </el-form-item>   
                 <el-form-item label="车牌号">
                  <el-input v-model="state.query.licensePlate" placeholder="车牌号" ></el-input>
                </el-form-item>   
                <el-form-item style="margin-right:10px">                
                <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-table
                :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading"
                >
                <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="realName" label="真实姓名" min-width="100" align="center"></el-table-column>
                <el-table-column prop="idCardNo" label="身份证" min-width="150" align="center"></el-table-column>
                <el-table-column prop="licensePlate" label="车牌号" min-width="120" align="center"></el-table-column>
                <el-table-column prop="createDate" label="申请时间" min-width="160" align="center"></el-table-column>
                <el-table-column prop="approvalStatus" label="状态" min-width="100" align="center">
                <template #default="{row}">
                  <el-tag
                    :type="(row.approvalStatus == 2 ? 'success' : (row.approvalStatus == 1 ? 'info' : (row.approvalStatus == 3 ? 'danger' : '')))"
                    disable-transitions
                  >{{ (row.approvalStatus == 2 ? '通过' : (row.approvalStatus == 1 ? '申请中' : (row.approvalStatus == 3 ? '不通过' : '全部')))}}
                  </el-tag>
                </template>
                </el-table-column>
                <el-table-column label="操作" min-width="180" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverapplycancel:get`])">
                  <template #default="scope">
                      <el-button
                          type="text"
                          icon="Check"
                          @click="handleClickDetails(scope.row)"
                          v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverapplycancel:get`])"
                      >查看详情</el-button>
                      <el-button
                          type="text"
                          icon="Tickets"
                          v-show="isShow(scope.row)"
                          @click="handleClickToExamine(scope.row)"
                           v-if="checkPermission([`api${state.VIEW_VERSION}:driver:driverapplycancel:updateapplyapproval`])"
                      >审核</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->
         <!-- 审核司机申请 begin -->
        <el-dialog title="审核司机解绑" v-model="state.dialogSJVisible" width="600px" :close-on-click-modal="false">
          <el-form :model="state.formAudit ">
            <el-form-item label="审核结果">
              <el-select v-model="state.formAudit.examine" placeholder="请选择审核结果">
                <el-option label="通过" value="2"></el-option>
                <el-option label="不通过" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核意见">
              <el-input type="textarea" :rows="6"  placeholder="请输入内容" v-model="state.formAudit.Reviewopinions" class="nanme">
            </el-input>
            </el-form-item>
          </el-form>
           <template #footer>
          <span class="dialog-footer">
             <el-button @click="state.dialogSJVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitAudit">确 定</el-button>
          </span>
        </template>
        </el-dialog>
        <!-- 审核司机申请 end -->

         <!-- 详情资料窗口 begin -->
        <el-dialog 
          title="详情资料" 
          :close-on-click-modal="false"
          v-model="state.detailsVisible">
              <el-form
                ref="editForm"
                :model="state.detailsDriverInfo.detailsInfo"
                label-width="100px"
                :inline="false"
              >
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="真实姓名" prop="realName" >
                        <el-input v-model="state.detailsDriverInfo.detailsInfo.realName" autocomplete="off" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="身份证号" prop="idCardNo">
                        <el-input v-model="state.detailsDriverInfo.detailsInfo.idCardNo" autocomplete="off" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="车牌号" prop="licensePlate">
                        <el-input v-model="state.detailsDriverInfo.detailsInfo.licensePlate" autocomplete="off" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="身份证正面" prop="idCardFront">
                        <el-image  :src="state.detailsDriverInfo.detailsInfo.idCardFrontImgUrl" :preview-src-list="[state.detailsDriverInfo.detailsInfo.idCardFrontImgUrl]"  class="imgto" style="width: 146px; height: 146px">
                        </el-image>
                        <!-- <Eup-Cropper :src="state.detailsDriverInfo.detailsInfo.idCardFrontImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="身份证反面" prop="IdCardBack">
                        <el-image  :src="state.detailsDriverInfo.detailsInfo.idCardBackImgUrl" :preview-src-list="[state.detailsDriverInfo.detailsInfo.idCardBackImgUrl]"  class="imgto" style="width: 146px; height: 146px"></el-image>
                        <!-- <Eup-Cropper :src="state.detailsDriverInfo.detailsInfo.idCardBackImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                      </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="行驶证正面" prop="drivingLicenseFront">
                        <el-image  :src="state.detailsDriverInfo.detailsInfo.drivingLicenseFrontImgUrl" :preview-src-list="[state.detailsDriverInfo.detailsInfo.drivingLicenseFrontImgUrl]"  class="imgto" style="width: 146px; height: 146px"></el-image>
                        <!-- <Eup-Cropper :src="state.detailsDriverInfo.detailsInfo.drivingLicenseFrontImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <el-form-item label="行驶证反面" prop="drivingLicenseCarImg">
                        <el-image  :src="state.detailsDriverInfo.detailsInfo.drivingLicenseCarImgUrl" :preview-src-list="[state.detailsDriverInfo.detailsInfo.drivingLicenseCarImgUrl]"  class="imgto" style="width: 146px; height: 146px"></el-image>
                        <!-- <Eup-Cropper :src="state.detailsDriverInfo.detailsInfo.drivingLicenseCarImgUrl" :aspectRatio="4/3" :readonly="true"></Eup-Cropper> -->
                      </el-form-item>
                    </el-col>
                  <!-- </el-col> -->
                </el-row>
              </el-form>
        </el-dialog>
        <!-- 详情资料窗口 end -->
    </div>
</template>
<script>
import {reactive,onMounted} from "vue";
import { ElMessage } from 'element-plus';
import {DriverApplyCancelGetPage,DriverApplyCancelGet,updateDriverApplyCancel} from "@/serviceApi/driver/driverapplyCancel"
import EupPagination from "../../../components/EupPagination.vue";
import EupCrumbs from "../../../components/eup-crumbs/index.vue";
import EnumConfig from "@/enum/EnumConfig";
import EupCropper from '@/components/eup-uploadPhoto/eup-cropper.vue';
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
    name: "ApplyCancel",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs,
        EupCropper: EupCropper
    },
    setup() {
        const state=reactive({
             VIEW_VERSION: VIEW_VERSION,
            query: {
                approvalStatus: "1",
                idCardNo: "",
                realName: "",
                licensePlate:""
            },
            pageIndex: 1,
            pageSize: 10,
             detailsDriverInfo: {
              //详情信息
              detailsInfo: {
                realName: "",
                idCardNo: "",
                licensePlate: "",
                idCardFrontImgUrl: "",
                idCardBackImgUrl: "",
                drivingLicenseCarImgUrl: "",
                drivingLicenseFrontImgUrl: "",
              }
            },
             options: [{
              value: '0',
              label: '全部'
            },{
              value: '1',
              label: '申请中'
            }, {
              value: '2',
              label: '通过'
            }, {
              value: '3',
              label: '不通过'
            }],
            tableData:[],
            multipleSelection: [],
            loading: false,
            detailsVisible: false,
            dialogSJVisible: false,//审核司机对话框显示与隐藏
            AuditInfo: {},//需要审核信息
            formAudit:{
              examine: '2',//审核结果，默认通过， 2：通过   3：不通过
              Reviewopinions: "",//审核意见
            }//审核后的数据
        });
        onMounted(()=>{
            getData();
        });
        const getData=()=>{
        var params = {
                "currentPage": state.pageIndex,
                "pageSize": state.pageSize,
                "filter.approvalStatus": state.query.approvalStatus=='0'? null:state.query.approvalStatus,
                "filter.idCardNo": state.query.idCardNo,
                "filter.realName": state.query.realName,
                "licensePlate": state.query.licensePlate
            }
          state.loading = true;
          DriverApplyCancelGetPage(params).then((res)=>{
             if(res.code==1){
              state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
             }else{
               ElMessage.error(res.msg);   
             }
               state.loading = false;
          });
        };
         //审核司机信息
        const handleClickToExamine = ((row)=>{
          state.AuditInfo = row;
          state.dialogSJVisible = true;
          state.formAudit={
            examine: '2',//审核结果，默认通过， 2：通过   3：不通过
            Reviewopinions: ""//审核意见
          }
        });
           //查看
        const handleClickDetails = ((row) =>{
          state.detailsVisible  = true;
          //获取详情信息
          DriverApplyCancelGet(row.applyId).then((res)=>{
            if(res.code==1){
               state.detailsDriverInfo.detailsInfo=res.data;
            }else{
              ElMessage.error(res.msg);
            }
          });
        });
           //提交审核
        const submitAudit = ()=>{
          var params ={
            "applyId": state.AuditInfo.applyId,
            "approvalStatus": state.formAudit.examine,
            "approvalResult": state.formAudit.Reviewopinions,
          };

          updateDriverApplyCancel(params).then(res =>{
            if(res.code == 1){
              ElMessage.success('审核成功');
              state.dialogSJVisible = false;//关闭司机对话框
              getData();//刷新表单数据
            } else {
              ElMessage.error(res.msg);   
            }
            state.loading = false;
          });
        }

         //是否显示审核按钮
        const isShow = ((row)=>{
          if (row.approvalStatus == 1){
            return true;
          } else {
            return false;
          }
        });
         // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }
        //切换审核状态
        const approvalStatusHandle = ()=>{
          getData();
        }

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }
        return{
        state,approvalStatusHandle,resPageData,handleSelectionChange,handleSearch,getData,isShow,handleClickToExamine,submitAudit,handleClickDetails
        }
    }
}
</script>