/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:21:00
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取用户订单列表信息
export const getPageOrderList = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Order/OrderInfo/GetPage`, params);
}
//获取司机订单详情
export const GetDriverPageList = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Order/OrderInfo/GetDriverPage`, params);
}
//获取司机订单详情
export const GetDriver = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Order/OrderInfo/GetDriver`, params);
}
//获取订单详情
export const getOrderDetail = (params)=>{
    return request.apiGet(`/api${API_VERSION}/Order/OrderInfo/Get`, params);
}
//后台取消订单
export const updateSysCancelOrder = (params)=>{
    return request.apiPut(`/Api${API_VERSION}/Order/OrderInfo/UpdateSysCancelOrder`, params)
}
