
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取用户地址集合
export const  getPageAddressList  = (params)=>{
    return request.apiGet(`/api${API_VERSION}/User/Address/GetPage`, params);
}
//获取用户地址信息
export const  getAddressInfo  = (params)=>{
    return request.apiGet(`/api${API_VERSION}/User/Address/Get`, params);
}
//更新用户地址信息
export const updateUserAddress = (params)=>{
    return request.apiPut(`/api${API_VERSION}/User/Address/Update`, params);
}
//添加用户地址信息
export const AddUserddress = (params)=>{
    return request.apiPost(`/api${API_VERSION}/User/Address/Add`, params);
}
//删除用户地址信息
export const DeleteUserddress = (params)=>{
    return request.apiDelete(`/api${API_VERSION}/User/Address/Delete?id=${params}`);
}
//批量删除用户地址信息
export const BatchDeleteUserddress = (params)=>{
    return request.apiPut(`/api${API_VERSION}/User/Address/BatchDelete`, params);
}
