<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-05-24 10:24:20
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:15:54
-->
<template>
    <div>
        <!-- 面包屑 begin -->
        <eup-crumbs icon="ScaleToOriginal" firstCrumbs="运费管理" secondCrumbs="价格配置" />
        <!-- 面包屑 end -->

        <!-- 内容区域 begin -->
        <div class="container">
            <div class="handle-box">
              <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:GetPage`,`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:Add`,`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:BatchSoftDelete`])">
                <template v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:GetPage`])">
                  <el-form-item label="地区">
                    <el-input v-model="state.query.regionName" placeholder="地区" ></el-input>
                  </el-form-item> 
                  <el-form-item label="车辆类型">
                    <el-select v-model="state.query.carId" filterable placeholder="车辆类型" @change="changeCarTypeHandle" class="handle-select mr10">
                      <el-option
                        v-for="item in state.carTypes"
                        :key="item.carId"
                        :label="item.carName"
                        :value="item.carId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="计价类型">
                    <el-select v-model="state.query.calcRuleType" placeholder="计价类型" class="handle-select mr10" @change="calcRuleTypeHandle">
                      <el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="是否有效">
                    <el-select v-model="state.query.isActive" placeholder="是否有效" class="handle-select mr10" @change="isActiveHandle">
                      <el-option v-for="item in state.opisActive" :key="item.isActive" :label="item.label" :value="item.isActive"></el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <el-form-item>
                  <el-button type="primary" icon="Search" @click="handleSearch" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:GetPage`])">搜索</el-button>
                <el-button type="primary" icon="Plus" @click="handleClickAddData" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:Add`])">新增</el-button>
                <el-button
                    type="danger"
                    icon="Delete"
                    class="handle-del mr10"
                    @click="handleClickBatchDelete(this)"
                    v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:BatchSoftDelete`])"
                >批量删除</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-table
                :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading"
            >
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="carName" label="车辆类型" min-width="100" align="center"></el-table-column>
                <el-table-column prop="calcRuleType" label="计价类型" min-width="100" align="center" :formatter="hasBackFormat"></el-table-column>
                <el-table-column prop="condition" label="计价量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="freight" label="运费" min-width="100" align="center"></el-table-column>
                <el-table-column prop="isActive" label="计价是否有效" min-width="100" align="center" :formatter="hasValidFormat"></el-table-column>
                <el-table-column prop="regionName" label="地区" min-width="150" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="150" align="center"></el-table-column>
                <el-table-column label="操作" min-width="180" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:Update`,`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:SoftDelete`])">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="Edit"
                            @click="handleEdit(scope.$index,scope.row)"
                            v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:Update`])"
                        >编辑</el-button>
                        <el-button
                            type="text"
                            icon="Delete"
                            class="red"
                            @click="handleClickDelete(scope.row, cb_Delete_Func, this)"
                            v-if="checkPermission([`api${state.VIEW_VERSION}:Delivery:DeliveryPriceCalcRule:SoftDelete`])"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->

        <!-- 添加/编辑窗口 begin -->
        <el-dialog 
          :title="state.dialogTitle"
          v-model="state.addDialogFormVisible"
          :close-on-click-modal="false"
          width="60%"
          @close="closeDialog">
            <el-form
                ref="refAddForm"
                :model="state.addForm"
                label-width="80px"
                :inline="false"       
              >
              <el-row>
                <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18"> -->
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="车辆类型" prop="carName">
                        <el-select v-model="state.addForm.carId" placeholder="请选择" :disabled=state.isEdit>
                            <el-option
                              v-for="item in (state.isEdit ? state.allCarNames : state.carNames)"
                              :key="item.carId"
                              :label="item.carName"
                              :value="item.carId"
                            ></el-option
                          ></el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="计价类型" prop="priceType">
                          <el-select v-model="state.addForm.priceType" placeholder="请选择" :disabled=state.isEdit @change="counhosyadd">
                            <el-option
                              v-for="item in state.optionsedit"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option
                          ></el-select>
                        </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="state.conditionName" prop="condition">
                      <el-input v-model="state.addForm.condition" :disabled=state.isEdit> <template #append>{{state.conshistyy}}</template></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="运费" prop="freight">
                      <el-input v-model="state.addForm.freight" autocomplete="off" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="是否有效" prop="isActive">
                      <el-select v-model="state.addForm.isActive" placeholder="请选择">
                            <el-option
                              v-for="item in state.eidtOrAddActive"
                              :key="item.isActive"
                              :label="item.label"
                              :value="item.isActive"
                            ></el-option
                          ></el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="备注" prop="remark">
                      <el-input v-model="state.addForm.remark" autocomplete="off" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="省" prop="province">
                        <el-select v-model="state.province" placeholder="请选择" @change="changeProvince" :disabled=state.isEdit>
                        <el-option
                          v-for="item in state.regionOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6" v-if="(state.isEdit ? (state.province != 0 && state.city != '') : true)">
                    <el-form-item label="市" prop="city">
                        <el-select v-model="state.city" placeholder="请选择" :disabled=state.isEdit>
                        <el-option
                          v-for="item in state.cityOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                <!-- </el-col> -->
              </el-row>
            </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeDialog()">取 消</el-button>
              <el-button type="primary" @click="addDialogFormSave(this)">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 添加/编辑窗口 end -->
    </div>
</template>

<script>
import {reactive,onMounted, ref} from "vue"
import { ElMessage } from 'element-plus'
import { getPriceConfigList, postpriceadd, putpudeaprice,detletpeice,putdelectprice} from "@/serviceApi/freight/priceConfig";
import { getSelectList} from "@/serviceApi/system/areaManage"
import { postcar} from "@/serviceApi/freight/vehicleConfig";
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;


export default {
    name: "PriceConfig",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
    setup(){    
        const refAddForm = ref(null);
        const state = reactive({
          VIEW_VERSION: VIEW_VERSION,
            query: {
                carId: "",
                calcRuleType: "0",
                isActive: "0",
                regionName: ""
            },
            pageIndex: 1,
            pageSize: 10,
            tableData: [],
            multipleSelection: [],
            delList: [],
            pageTotal: 0,
            form: {},
            idx: -1,
            loading: false,
            hideUpload: false,
            limitCount: 1,
            editInnerVisible: false,
            PWEidtInfo:{
                newPassword: "",
                confirmPassword: "",
                userId: ""
            },
            detailsVisible: false,
            oldImgUrl: "",
            deleteImgData:{
                isDelete: false,
                rowId: 0
            },
            conshistyy: 'km',//计价量
            conditionName: "距离",//计价量名称
            addDialogFormVisible: false,
            dynamicFilter:{},
            dialogTitle: "",
            // 新增界面数据
            addForm: {carName: "",priceType: 1,condition: "",freight: "",isActive: '1',remark: "", carId: "",priceRuleId:""},
            carNames: [], //车辆类型数组(不包含无效的)
            carTypes : [], //车辆类型数组带全部的查询条件
            //计价类型(查)
            options: [
              {
              value: '0',
              label: '全部'
            },{
              value: '1',
              label: '距离(km)'
            }, {
              value: '2',
              label: '重量(kg)'
            }, {
              value: '3',
              label: '体积(m³)'
            }, {
              value: '4',
              label: '面积(㎡)'
            }],
            optionsedit: [{
              value: '1',
              label: '距离(km)'
            }, {
              value: '2',
              label: '重量(kg)'
            }, {
              value: '3',
              label: '体积(m³)'
            }, {
              value: '4',
              label: '面积(㎡)'
            }],
            //是否有效枚举集
            opisActive: [{isActive: '0',label: '全部'},{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
            eidtOrAddActive:[{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
            //新增校验
            addFormRules: {
              condition: [
                { 
                  required: true, message: '请输入计价量', trigger: 'blur' }
                ],
              freight: [
                { 
                  required: true, message: '请输入运费', trigger: 'blur' }
              ]
            },
            regionOptions: [{value: 0,label: "全国"}],
            cityOption:[],
            wholeCountry:[],
            regionId:"",
            province: "",
            city: "",
            allCarNames: [],//车辆类型数组(包含无效的)
            isEdit: false,
            refForm: null,
        });
        onMounted(()=>{
          getData();
          //获取车辆类型(包含无效的)
           let params ={
             "Filter.CarName":state.addForm.carName
           }
          postcar(params).then(res => {
            if(res.code == 1){
              state.allCarNames = res.data.list;
              if (res.data.list.length > 0){
                var data = res.data.list.filter(data => {
                  if (data.isActive == 1){
                    return data;
                  }
                });
                state.carNames = data;
                state.carTypes = [];
                state.carTypes = JSON.parse(JSON.stringify(data));
                state.carTypes.unshift({carName: "全部", carId: ""})
                state.addForm.carName = state.isEdit ? state.allCarNames[0].carName : state.carNames[0].carName;//默认选择第一个
              }
            } else {
              ElMessage.error(res.msg);
            }
          });
        });
        //获取表单信息
        const getData=()=>{
            var params = {
                "currentPage": state.pageIndex,
                "pageSize": state.pageSize,
                "filter.carId": state.query.carId,
                "filter.calcRuleType": state.query.calcRuleType=='0'? null:state.query.calcRuleType,
                "filter.isActive": state.query.isActive=='0'? null:state.query.isActive,
                "filter.regionName": state.query.regionName,
                "dynamicFilter": state.dynamicFilter
            }
            state.loading = true;
            getPriceConfigList(params).then(function(res){
                if(res.code == 1){
                state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
                state.deleteImgData.isDelete = false;
                state.deleteImgData.rowId = 0;
                } else {
                ElMessage.error(res.msg);   
                }
                state.loading = false;
            });
        }
        // 编辑操作
        const handleEdit=(index, row)=> {
          var strJson = JSON.stringify(row);
          var jsonRow = JSON.parse(strJson)
          state.isEdit = true;
          state.dialogTitle = "编辑价格配置";
          state.addDialogFormVisible = true;
          state.province = "";
          state.city = "";
          state.addForm = {
            "carName": jsonRow.carName,
            "priceType": jsonRow.calcRuleType + "",
            "condition": jsonRow.condition,
            "freight": jsonRow.freight,
            "isActive": jsonRow.isActive + '',
            "remark": jsonRow.remark,
            "carId": jsonRow.carId,
            "priceRuleId": jsonRow.priceRuleId,
            "regionId": jsonRow.regionId
          }
          //切换计价类型
         counhosyadd();
          state.idx = index;
          getSelectList_Area(0, ()=>{
            if (jsonRow.regionFullId == undefined || jsonRow.regionFullId == null || jsonRow.regionFullId == ""){
              state.province = 0;
            } else {
              state.province =jsonRow.regionFullId.split(',')[0] == 0 ? jsonRow.regionId : jsonRow.regionFullId.split(',')[0];
            }
            if (jsonRow.regionId != "" && jsonRow.regionFullId != "" && jsonRow.regionFullId != "0"){
              getCity(jsonRow.regionFullId, ()=>{
                if (jsonRow.regionId == undefined || jsonRow.regionId == null || jsonRow.regionId == ""){
                  state.city = "";
                } else {
                  state.city = jsonRow.regionId;
                }
              });
            }
          });
        }
        // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }
        //删除
        const handleClickDelete = (row, callback, t) =>{
          var id = row.priceRuleId;
          if (callback){
            callback(id, t);
          }
        }
        //批量删除
        function handleClickBatchDelete(t){
          if(state.multipleSelection.length == 0){//未选中
            ElMessage.error("请选择要删除的数据！");
          } else {
            var arrIds = state.multipleSelection.map(s =>{
              return s.priceRuleId;
            });
            t.$confirm('此操作将删除选中的记录, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              putdelectprice(arrIds).then(res=>{
                if (res.code == 1){
                  ElMessage.success("操作成功！");
                  getData();
                } else {
                  ElMessage.error("操作失败！");
                }
              }).catch(err=>{
                ElMessage.error(err.msg);
              });
            }).catch(err=>{
              ElMessage.info("取消批量删除！");
            });
          }
        }
        //点击新增
        const handleClickAddData = () =>{
          state.isEdit = false;
          state.addDialogFormVisible = true;
          state.dialogTitle = "新增价格配置";
          state.disCarType = false;
          state.disPriceType = false;
          state.disCondition = false;
          state.disProvince = false;
          state.disCity = false;
          //清空历史数据
          state.addForm = {
            carName: (state.carNames.length > 0) ? state.carNames[0].carName : "",
            priceType: "1",
            condition: "",
            freight: "",
            isActive: '1',
            remark: "",
            carId: state.addForm.carId ? state.addForm.carId: ((state.carNames.length > 0) ? state.carNames[0].carId : "")
          };
          state.province = "";
          state.city = "";
          state.cityOption = [];
          state.conshistyy= 'km';
          state.conditionName= "距离";
          getSelectList_Area(0);
        }
        //切换省份/地级市
        const changeProvince = (val)=>{
          getCity(val);
          state.city = "";
        }
        const getSelectList_Area = (regionId, callback)=>{
          state.wholeCountry = [];//清空历史数据
          state.regionOptions = [{value: 0,label: "全国"}];
          //先判断全国第一级下拉菜单是否为空，如果为空要调用接口获取
          getSelectList(regionId).then((res)=>{
            if (res.code == 1){
              state.wholeCountry = res.data;
              res.data.forEach(function(data, index){
                var subJson = {};
                subJson.value = data.regionId;
                subJson.label = data.regionName;
                state.regionOptions.push(subJson);//添加一级下拉菜单
              });
              if (callback){
                callback();
              }
            }else {
              ElMessage.error(res.msg);
            }
          }).catch((err)=>{
            ElMessage.error(err.msg)
          });       
        }
        const getCity = (pregionId,callback)=>{
          state.cityOption = [];
          //通过省地区ID去后台获取子城市
          getSelectList(pregionId).then((res)=>{
            if (res.code == 1){
              res.data.forEach(function(cityData, cityIndex){
                var subJson = {};
                subJson.value = cityData.regionId;
                subJson.label = cityData.regionName;
                state.cityOption.push(subJson);//添加二级菜单子城市
              });
              if (callback){
                callback();
              }
            }else {
              ElMessage.error(res.msg);
            }
          }).catch((err)=>{
            ElMessage.error(err.msg);
          });
        }
        const hasBackFormat = ((row,column) => {
          if (row.calcRuleType== 1) {
            return '距离(km)'
          }
          else if (row.calcRuleType==2) {
            return '重量(kg)'
          }
          else if (row.calcRuleType==3) {
            return '立方米(m³)'
          }
          else {
            return '平方米(㎡)'
          }
        });
        const hasValidFormat = ((row) => {
          if (row.isActive == 1){
            return "有效";
          } else if (row.isActive == 2){
            return "无效";
          }
        });
        //切换计价类型
        const counhosyadd = () => {
          if (state.addForm.priceType==1 ) {
            state.conditionName = '距离'
            state.conshistyy='km'
          }
          else if (state.addForm.priceType==2) {
            state.conditionName = '重量'
            state.conshistyy='kg'
          }
          else if (state.addForm.priceType==3) {
            state.conditionName = '体积'
            state.conshistyy='m³'
          }
          else {
            state.conditionName = '面积'
            state.conshistyy='㎡'
          }
        }
        //保存新增/编辑价格配置
        const addDialogFormSave = (t)=>{
          //先空判断
          if (state.addForm.condition === ""){
            ElMessage.error("计价量不能为空");
            return;
          }
          if (state.addForm.freight === ''){
            ElMessage.error("运费不能为空");
            return;
          }
          if (state.province === ''){
            ElMessage.error("请选择省/地级市");
            return;
          }     
          //新增时做校验     
          if (!state.isEdit && state.province != "" && state.city == ''){
            ElMessage.error("请选择城市");
            return;
          }
        
          var params = {};
          var operType = 0;//操作类型
          if(state.dialogTitle == "新增价格配置"){
            //参数封装
            params = {
              "carId": state.addForm.carId,
              "calcRuleType": state.addForm.priceType ? state.addForm.priceType : 1,
              "condition": state.addForm.condition ? state.addForm.condition : 0,
              "freight": state.addForm.freight ? state.addForm.freight : 0,
              "isActive": state.addForm.isActive ? state.addForm.isActive : 1,
              "remark": state.addForm.remark,
              "regionId": state.city ? state.city : (state.province ? state.province : 0)
            }
            operType = 1;
          } else {
            //参数封装
            params = {
              "carId": state.addForm.carId,
              "calcRuleType": state.addForm.priceType ? state.addForm.priceType : 1,
              "condition": state.addForm.condition ? state.addForm.condition : 0,
              "freight": state.addForm.freight ? state.addForm.freight : 0,
              "isActive": state.addForm.isActive ? state.addForm.isActive : 1,
              "remark": state.addForm.remark,
              "priceRuleId": state.addForm.priceRuleId,
              "version": 0,
              "regionId": state.city ? state.city : (state.province ? state.province : 0)
            }
            operType = 2;
          }
          if (1 == operType) {//新增
            postpriceadd(params).then(res =>{
              if (res.code == 1){
                t.$message({
                  type: 'success',
                  message: '提交成功!'
                });
                getData();
                state.addDialogFormVisible = false;
              } else {
                t.$message({
                  type: 'warning',
                  message: res.msg
                });
              }
            }).catch(err=>{
              t.$message({
                type: 'error',
                message: '提交失败！'
              });
            }); 
          } else if (2 == operType){//编辑
            putpudeaprice(params).then(res =>{
              if (res.code == 1){
                t.$message({
                  type: 'success',
                  message: '提交成功!'
                });
                getData();
                state.addDialogFormVisible = false;
              } else {
                t.$message({
                  type: 'warning',
                  message: res.msg
                });
              }
            }).catch(err=>{
              t.$message({
                type: 'error',
                message: '提交失败！'
              });
            }); 
          }
        }

        //删除回调
        const cb_Delete_Func = function(params,t){
          t.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              detletpeice(params).then(res =>{
                if (res.code == 1){
                  t.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  //刷新表单
                  getData();
                } else {
                  t.$message({
                    type: 'warning',
                    message: res.msg
                  });
                }
              }).catch(err=>{
                t.$message({
                  type: 'error',
                  message: '删除失败！'
                });
              });    
            }).catch(() => {
              t.$message({
                type: 'info',
                message: '已取消删除'
              });
          });
        }

        //关闭对话框
        const closeDialog = ()=>{
          state.addDialogFormVisible = false;
          refAddForm.value.resetFields();
          state.addForm =  {carName: "",priceType: 1,condition: "",freight: "",isActive: '1',remark: "", carId: "",priceRuleId:""};
        }
        //切换计价类型
        const calcRuleTypeHandle = ()=>{
          getData();
        };
        //切换是否有效
        const isActiveHandle = ()=>{
          getData();
        }

        //列表查询切换车辆类型
        const changeCarTypeHandle = (option)=>{
          state.addForm.carId = option;
          getData();
        }

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }
        return {
            state,
            handleSelectionChange,
            handleSearch,
            handleEdit,
            handleClickDelete,
            handleClickBatchDelete,
            handleClickAddData,
            addDialogFormSave,
            cb_Delete_Func,
            counhosyadd,
            hasValidFormat,
            hasBackFormat,
            changeProvince,
            closeDialog,
            refAddForm,
            calcRuleTypeHandle,
            isActiveHandle,
            changeCarTypeHandle,
            getData,
            resPageData
        }
    }
};
</script>
<style>
</style>
