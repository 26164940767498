/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2022-02-10 13:51:13
 */
import {createApp} from 'vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import store from './store'
import router from './router'
import './assets/css/icon.css'
import installElementPlusETC from './plugins/element';
import * as Icons from '@element-plus/icons';
const app = createApp(App);
installElementPlusETC(app);
app.use(store).use(router).mount('#app');


